import { IUserData } from './profileJson.model';
export const ProileJSON: Array<IUserData> = [
  {
    category: 'general',
    position: 'left',
    name: 'Full name',
    key: 'name',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Job title',
    key: 'job_title_id',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Phone',
    key: 'mobile_phone',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Email',
    key: 'work_email',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Company',
    key: 'company_id',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Department',
    key: 'department_id',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Join date',
    key: 'join_date',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Line manager',
    key: 'line_manager_name',
    content: '',
  },
  {
    category: 'personal',
    position: 'left',
    name: 'Date of birth',
    key: 'birthday',
    content: '',
  },
  {
    category: 'personal',
    position: 'left',
    name: 'Gender',
    key: 'gender',
    content: '',
  },
  {
    category: 'personal',
    position: 'left',
    name: 'Nationality',
    key: 'nationality_id',
    content: '',
  },
  {
    category: 'personal',
    position: 'right',
    name: 'Phone',
    key: 'phone',
    content: '',
  },
  {
    category: 'personal',
    position: 'right',
    name: 'Personal email',
    key: 'private_email',
    content: '',
  },
  {
    category: 'personal',
    position: 'right',
    name: 'Marital Status',
    key: 'marital',
    content: '',
  },

  // {
  //   category: 'personal',
  //   position: 'right',
  //   name: 'Identification No',
  //   key: 'identification_number',
  //   content: '',
  // },
  // {
  //   category: 'personal',
  //   position: 'right',
  //   name: 'Identification Issued Date',
  //   key: 'identification_issue_date',
  //   content: '',
  // },
  // {
  //   category: 'personal',
  //   position: 'right',
  //   name: 'Work Permit No',
  //   key: 'permit_no',
  //   content: '',
  // },
  // {
  //   category: 'personal',
  //   position: 'right',
  //   name: 'Work Permit Exp Date',
  //   key: 'permit_exp',
  //   content: '',
  // },
  {
    category: 'emergency',
    position: 'right',
    name: 'Name',
    key: 'partner_relationship_ids',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: 'Residential Address',
    key: 'residential_street',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'residential_ward_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'residential_district_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'residential_state_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'residential_country_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: 'Permanent Address',
    key: 'permanent_street',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'permanent_ward_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'permanent_district_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'permanent_state_id',
    content: '',
  },
  {
    category: 'address',
    position: 'left',
    name: '',
    key: 'permanent_country_id',
    content: '',
  },
  {
    category: 'bank',
    position: 'left',
    name: 'Account Holder Name',
    key: 'bank_acc_holder',
    content: '',
  },
  {
    category: 'bank',
    position: 'left',
    name: 'Account No.',
    key: 'bank_acc_number',
    content: '',
  },
  {
    category: 'bank',
    position: 'left',
    name: 'Bank Name',
    key: 'bank_name',
    content: '',
  },
  // {
  //   category: 'documents',
  //   position: 'left',
  //   name: 'Document Type',
  //   key: 'identification_id_number',
  //   content: '',
  // },
  // {
  //   category: 'documents',
  //   position: 'right',
  //   name: 'Issue Date',
  //   key: 'identification_id_issue_date',
  //   content: '',
  // },
  //   {
  //     category: 'documents',
  //     position: 'right',
  //     name: 'Expire Date',
  //     key: 'expire_date',
  //     content: '',
  //   },
];
