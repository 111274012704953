import {
  BrowserRouter,
  Outlet,
  Route,
  RouteProps,
  Routes,
} from 'react-router-dom';
import Login from './pages/login/Login';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Dashboard from 'pages/dashboard/Dasboard';
import {
  DASHBOARD,
  EMPLOYEE,
  LOGIN,
  PAGE404,
  PERSONAL,
  TEAM_MANAGEMENT,
  RECRUITMENT,
  HR_ADMINISTRATION,
  LEAVE_HOLIDAYS,
  SALARY,
  DIRECTORY,
  ORG_CHART,
  DOCUMENTS,
  APPROVALS,
  REQUEST_MANAGEMENT,
  INTERVIEWS,
  PERFORMANCE,
  EMPLOYMENT,
  EMPLOYEE_MANAGEMENT,
  OFFERS,
  EMPLOYEES,
  CONTRACT,
  ALL_RESIGNATIONS,
  WORK_PERMITS,
  VISAS,
  ALL_LEAVE_REQUESTS,
  MASTER_DATA,
  ALL_EMPLOYEES,
  STAFF_MOVEMENT,
  POSITION,
  ALL_CONTRACT,
  ALL_APPENDIXES,
  CONTRACTS,
  REQUEST_NEW_HIRE,
  LEAVE_REQUESTS,
  RESIGNATIONS_REQUESTS,
  PERSONAL_DOCUMENTS,
  DOCUMENTS_CONTROL_SYSTEM,
  VISA_WORK_PERMIT,
  RESIGNATIONS,
  PERSONAL_DASHBOARD,
} from 'utils/route_name';
import { RouteInfo } from 'models/routeInfo';
import React, { ReactElement } from 'react';

import {
  CalendarIcon,
  ContractIcon,
  EmployeeIcon,
  PayrollIcon,
  StatisticIcon,
} from 'assets/AssetUtil';
import Statistic from 'pages/statistic/Statistic';
import Profile from 'pages/profile/Profile';
import Home from 'pages/home/Home';
import ILAModal from 'components/modal/ILAModal';
import UserLeave from 'pages/user_leave/UserLeave';
// import ProtectedRoute from 'ProtectedRoute';
import ApprovalLeave from 'pages/approval_leave/ApprovalLeave';

export const MainMenuRoute: Array<RouteInfo> = [
  {
    level: 1,
    path: '',
    title: 'Dashboard',
    component: <Home />,
  },
  {
    path: PERSONAL,
    level: 1,
    icon: EmployeeIcon,
    title: 'Personal',
    component: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: PERSONAL_DASHBOARD,
        level: 2,
        title: 'Dashboard',
        component: <Home />,
        isPinnedMenu: false,
      },
      {
        path: LEAVE_HOLIDAYS,
        level: 2,
        title: 'Leave and holidays',
        component: <UserLeave />,
        isPinnedMenu: false,
      },
      {
        path: SALARY,
        level: 2,
        title: 'Salary',
        component: <div>Salary Page</div>,
        isPinnedMenu: false,
      },
      {
        path: DOCUMENTS,
        level: 2,
        title: 'Documents',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: PERSONAL_DOCUMENTS,
            level: 3,
            icon: ContractIcon,
            title: 'Personal documents',
            component: <div>Personal documents Page</div>,
          },
          {
            path: DOCUMENTS_CONTROL_SYSTEM,
            level: 3,
            icon: ContractIcon,
            title: 'Document control system',
            component: <div>Document control system Page</div>,
          },
        ],
        isPinnedMenu: false,
      },
      {
        path: PERFORMANCE,
        level: 2,
        title: 'Performance',
        component: <div>Performance Page</div>,
        isPinnedMenu: false,
      },
      {
        path: EMPLOYMENT,
        level: 2,
        title: 'Employment',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: CONTRACTS,
            level: 3,
            icon: ContractIcon,
            title: 'Contracts',
            component: <div>Contracts Page</div>,
          },
          {
            path: VISA_WORK_PERMIT,
            level: 3,
            icon: ContractIcon,
            title: 'Visa and work permit',
            component: <div>Visa and work permit Page</div>,
          },
          {
            path: RESIGNATIONS,
            level: 3,
            icon: ContractIcon,
            title: 'Resignation',
            component: <div>Resignation Page</div>,
          },
        ],
        isPinnedMenu: false,
      },
      {
        path: DIRECTORY,
        level: 2,
        title: 'Directory',
        component: <div>Directory Page</div>,
        isPinnedMenu: false,
      },
      {
        path: ORG_CHART,
        level: 2,
        title: 'Organization chart',
        component: <div>Org chart Page</div>,
        isPinnedMenu: false,
      },
    ],
    isPinnedMenu: false,
  },
  {
    level: 1,
    path: TEAM_MANAGEMENT,
    icon: PayrollIcon,
    title: 'Team management',
    component: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: APPROVALS,
        level: 2,
        title: 'Approvals',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: LEAVE_REQUESTS,
            level: 3,
            icon: ContractIcon,
            title: 'Leave requests',
            component: <ApprovalLeave />,
          },
          {
            path: RESIGNATIONS_REQUESTS,
            level: 3,
            icon: ContractIcon,
            title: 'Resignation requests',
            component: <div>Resignation requests Page</div>,
          },
        ],
        isPinnedMenu: false,
      },
      {
        path: EMPLOYMENT,
        level: 2,
        title: 'Employment',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: ALL_EMPLOYEES,
            level: 3,
            icon: ContractIcon,
            title: 'Request new hire',
            component: <div>Request new hire Page</div>,
          },
          {
            path: REQUEST_NEW_HIRE,
            level: 3,
            icon: ContractIcon,
            title: 'Probation assessment',
            component: <div>Probation assessment Page</div>,
          },
          {
            path: CONTRACTS,
            level: 3,
            icon: ContractIcon,
            title: 'Contracts',
            component: <div>Contracts Page</div>,
          },
          {
            path: PERFORMANCE,
            level: 3,
            icon: ContractIcon,
            title: 'Performance',
            component: <div>Performance Page</div>,
          },
        ],
        isPinnedMenu: false,
      },
    ],
    isPinnedMenu: false,
  },
  {
    level: 1,
    path: RECRUITMENT,
    icon: CalendarIcon,
    title: 'Recruitment',
    component: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: REQUEST_MANAGEMENT,
        level: 3,
        title: 'Request management',
        component: <div>Request management Page</div>,
        isPinnedMenu: false,
      },
      {
        path: INTERVIEWS,
        level: 3,
        title: 'Interviews',
        component: <div>Interviews Page</div>,
        isPinnedMenu: false,
      },
      {
        path: DOCUMENTS,
        level: 3,
        title: 'Documents',
        component: <div>Documents Page</div>,
        isPinnedMenu: false,
      },
    ],
    isPinnedMenu: false,
  },
  {
    level: 1,
    path: EMPLOYEE_MANAGEMENT,
    icon: ContractIcon,
    title: 'Employee management',
    component: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: OFFERS,
        level: 2,
        title: 'Offers',
        component: <div>Offers Page</div>,
        isPinnedMenu: false,
      },
      {
        path: EMPLOYEES,
        level: 2,
        title: 'Employees',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: ALL_EMPLOYEES,
            level: 3,
            icon: ContractIcon,
            title: 'All employees',
            component: <div>All employees Page</div>,
          },
          {
            path: STAFF_MOVEMENT,
            level: 3,
            icon: ContractIcon,
            title: 'Staff movement',
            component: <div>Staff movement Page</div>,
          },
          {
            path: POSITION,
            level: 3,
            icon: ContractIcon,
            title: 'Positions',
            component: <div>Positions Page</div>,
          },
        ],
        isPinnedMenu: false,
      },
      {
        path: CONTRACT,
        level: 2,
        title: 'Contract',
        component: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            path: ALL_CONTRACT,
            level: 3,
            icon: ContractIcon,
            title: 'All contracts',
            component: <div>All contracts Page</div>,
          },
          {
            path: ALL_APPENDIXES,
            level: 3,
            icon: ContractIcon,
            title: 'All appendixes',
            component: <div>All appendixes Page</div>,
          },
        ],
        isPinnedMenu: false,
      },

      {
        path: ALL_RESIGNATIONS,
        level: 2,
        title: 'All resignations',
        component: <div>All resignations Page</div>,
        isPinnedMenu: false,
      },
      {
        path: WORK_PERMITS,
        level: 2,
        title: 'Work permits',
        component: <div>Work permits Page</div>,
        isPinnedMenu: false,
      },
      {
        path: VISAS,
        level: 2,
        title: 'Visas',
        component: <div>Visas Page</div>,
        isPinnedMenu: false,
      },
      {
        path: ALL_LEAVE_REQUESTS,
        level: 2,
        title: 'All leave requests',
        component: <div>All leave requests Page</div>,
        isPinnedMenu: false,
      },
      {
        path: DOCUMENTS,
        level: 2,
        title: 'Documents',
        component: <div>Documents Page</div>,
        isPinnedMenu: false,
      },
    ],
    isPinnedMenu: false,
  },
  {
    level: 1,
    path: MASTER_DATA,
    icon: CalendarIcon,
    title: 'Master data',
    component: <div>Master data Page</div>,
    children: [],
    isPinnedMenu: false,
  },
  {
    level: 1,
    path: HR_ADMINISTRATION,
    icon: StatisticIcon,
    title: 'HR administration',
    component: <Statistic />,
    children: [],
    isPinnedMenu: false,
  },
];

export const DashboardRoute: Array<RouteInfo> = [
  ...MainMenuRoute,
  {
    path: EMPLOYEE,
    level: 2,
    title: 'Employees',
    component: <Profile />,
    isPinnedMenu: false,
  },
];

export const AppRouteConfig: Array<RouteInfo> = [
  {
    level: 0,
    path: LOGIN,
    title: 'Login',
    component: <Login />,
  },
  {
    level: 0,
    path: DASHBOARD,
    title: 'Home',
    isProtected: true,
    component: <Dashboard />,
    children: DashboardRoute,
  },
  {
    level: 0,
    path: PAGE404,
    title: 'Not found',
    component: <PageNotFound />,
  },
];

export const generateRoutes = (routeConfig: Array<RouteInfo>) => {
  const routes: Array<ReactElement> = [];
  if (!routeConfig) return [];

  routeConfig.forEach((item: RouteInfo) => {
    const routeProps: RouteProps = {
      element: item.component,
      path: item.path,
    };

    const route = (
      <Route key={item.path} {...routeProps}>
        {item.children &&
          item.children.length > 0 &&
          generateRoutes(item.children)}
      </Route>
    );

    routes.push(route);
  });

  return routes;
};

const AppRoute = () => {
  const routes = generateRoutes(AppRouteConfig);

  return (
    <BrowserRouter>
      <Routes>{routes}</Routes>
      <ILAModal />
    </BrowserRouter>
  );
};
export default React.memo(AppRoute);
