import React from 'react';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'slices/modalSlice';
import { RootState } from 'store';
import './modal.scss';

export type CustomModalProps = {
  size?: 'sm' | 'lg' | 'xl';
  fullscreen?:
    | true
    | string
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down';
  bsPrefix?: string;
  centered?: boolean;
  backdropClassName?: string;
  animation?: boolean;
  dialogClassName?: string;
  contentClassName?: string;
  dialogAs?: React.ElementType;
  scrollable?: boolean;
};

const ILAModal = () => {
  const { isShowModal, props } = useSelector((state: RootState) => state.modal);
  const { type, closeOutSide, modalProps, onCloseOutSide, sideBarPosition } =
    props || {};
  const dispatch = useDispatch();
  const backdrop = closeOutSide ? undefined : 'static';
  const closeModal = () => {
    if (!closeOutSide) return null;
    dispatch(hideModal());
    onCloseOutSide && onCloseOutSide();
  };

  if (type == 'sidebar')
    return (
      <Offcanvas
        show={isShowModal || false}
        onHide={closeModal}
        backdrop={backdrop}
        placement={sideBarPosition}
      >
        <Offcanvas.Body>{props?.children}</Offcanvas.Body>
      </Offcanvas>
    );
  return (
    <Modal
      show={isShowModal || false}
      {...modalProps}
      onHide={closeModal}
      backdrop={backdrop}
    >
      {props?.children}
    </Modal>
  );
};

export default ILAModal;
