import moment from 'moment';

const isValidDate = (date: string, format?: string) => {
  return moment(date, format, true).isValid();
};

const getCurrentDate = (format?: string) => {
  return moment().format(format);
};

const formatDate = (date: Date | string, format?: string) => {
  return moment(date).format(format);
};
export { isValidDate, getCurrentDate, formatDate };
