import React, { useEffect } from 'react';
import './login.scss';
import {
  useLazyGetOdooSessionQuery,
  useLazyLoginGoogleQuery,
} from '../../api/authApi';
import { useNavigate } from 'react-router-dom';
import { BgLogin, GoogleIcon, logoILA } from '../../assets/AssetUtil';
import { DASHBOARD } from 'utils/route_name';
import { useDispatch } from 'react-redux';
import { hideModal, ILAModalProps, showModal } from 'slices/modalSlice';
import {
  AuthStatus,
  updateAuthStatus,
  updateOdooSession,
} from 'slices/authSlice';
import ILAButton from 'components/button/CustomButton';

const LoadingDialog: ILAModalProps = {
  children: (
    <div className="d-flex flex-column align-items-center p-2">
      <div className="spinner-grow text-primary" role="status" />
      <span>Loading ...</span>
    </div>
  ),
  type: 'modal',
  modalProps: {
    centered: true,
  },
};

const Login: React.FC = () => {
  const [getOdooToken, odooTokenResponse] = useLazyGetOdooSessionQuery();
  const [loginGoogle, googleResponse] = useLazyLoginGoogleQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (odooTokenResponse.isSuccess && !odooTokenResponse.isFetching) {
      dispatch(hideModal());
      dispatch(updateOdooSession(odooTokenResponse.data));
      dispatch(updateAuthStatus(AuthStatus.AUTHORIZED));
      navigate(DASHBOARD);
    }
  }, [dispatch, navigate, odooTokenResponse]);

  useEffect(() => {
    if (
      googleResponse.isSuccess &&
      googleResponse.data &&
      !googleResponse.isFetching
    ) {
      dispatch(showModal(LoadingDialog));
      getOdooToken(googleResponse.data);
    }
  }, [dispatch, getOdooToken, googleResponse]);

  return (
    <>
      <div className="login-page">
        <div
          className="login-page__background"
          style={{
            backgroundImage: `url(${BgLogin})`,
          }}
        ></div>
        <div className="login-page__content">
          <img src={logoILA} className="ILA-logo" alt="logo" />
          <div className="login-page__main-content">
            <h2 className="title__medium">
              Please Sign In with Employee’s Google Account!
            </h2>
            <ILAButton
              btnType="danger"
              className="login-page__main-content__btn"
              onPress={() => loginGoogle()}
            >
              <GoogleIcon width={20} height={20} />
              <span className="ms-2 title__small">Sign In with Google</span>
            </ILAButton>
          </div>
        </div>
        <h3 className="login-page__copyright body__large">
          © Copyright ILA. All rights reserved.
        </h3>
      </div>
    </>
  );
};

export default Login;
