import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type LoadingProps = {
  isLoading: boolean;
};

const initialState: LoadingProps = {
  isLoading: false,
};

const LoadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setStartLoading: (state: LoadingProps) => {
      state.isLoading = true;
    },
    setFinishLoading: (state: LoadingProps) => {
      state.isLoading = false;
    },
  },
});

export const { setStartLoading, setFinishLoading } = LoadingSlice.actions;
export const loadingStateSelect = (state: RootState) => state.loading.isLoading;
export default LoadingSlice;
