import { NotificationIcon } from 'assets/AssetUtil';
import React from 'react';
import './notification.scss';
const NotificationItem = ({ title }: { title: string }) => {
  return (
    <div className="d-flex mt-3">
      <NotificationIcon className="me-2" />
      <h4 className="body__medium">{title}</h4>
    </div>
  );
};

export default NotificationItem;
