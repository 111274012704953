export enum PopUpMessageType {
  SUCCESSFUL = 'successful',
  INFORMATION = 'information',
  WARNING = 'warning',
  ERROR = 'error',
}

export type PopUpMessageProps = {
  type: PopUpMessageType;
  content?: string;
  isShowCancelBtn?: boolean;
  onCancelPress?: () => void;
  onActionPress?: () => void;
};

export type ButtonTitlesProps = {
  [PopUpMessageType.SUCCESSFUL]: string;
  [PopUpMessageType.WARNING]: string;
  [PopUpMessageType.ERROR]: string;
  [PopUpMessageType.INFORMATION]: string;
};

export type BadgesProps = {
  successful: JSX.Element;
  warning: string;
  error: string;
  information: string;
};
