import './list-view.scss';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChervonLeft,
  ChervonRight,
} from 'assets/AssetUtil';
import Table from 'react-bootstrap/Table';
import React, { ChangeEvent, useEffect, useState } from 'react';

export type ListViewHeader = {
  title: string;
  sortField?: string;
  onSortPress?: () => void;
};

export type ListViewProps<T> = {
  headers: Array<ListViewHeader>;
  className?: string;
  totalRow: number;
  itemCountPerPage: number;
  dataSet: Array<T>;
  renderRowItem: (item: T, index?: number) => React.ReactElement;
  onNextPageClick?: (offset: number) => void;
  onPrevPageClick?: (ofsset: number) => void;
};

export enum SortType {
  ASC,
  DESC,
}
const ListView = <T extends object>({
  headers,
  className,
  totalRow = 100,
  dataSet,
  renderRowItem,
  itemCountPerPage = 20,
  onNextPageClick,
  onPrevPageClick,
}: ListViewProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(dataSet);

  const [sortCol, setSortCol] = useState<{
    type: SortType;
    sortField: string;
  }>({
    type: SortType.DESC,
    sortField: '',
  });

  const totalPage = Math.ceil(totalRow / itemCountPerPage);
  const offset = (page: number) => (page - 1) * itemCountPerPage;

  const onNextBtnPress = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPage) {
      setCurrentPage(nextPage);
      onNextPageClick && onNextPageClick(offset(nextPage));
    }
  };

  const onPrevBtnPress = () => {
    if (currentPage == 1) return;
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    onPrevPageClick && onPrevPageClick(offset(prevPage));
  };

  const onSelectPage = (e: ChangeEvent<HTMLSelectElement>) => {
    const page = Number(e.target.value);
    if (page <= currentPage) onPrevBtnPress();
    else onNextBtnPress();
  };

  const startItemOffset = () => (totalRow > 0 ? offset(currentPage) + 1 : 0);

  const endItemOffset = () => {
    const end = offset(currentPage) + itemCountPerPage;
    return end > totalRow ? totalRow : end;
  };

  const sortData = (col: string) => {
    new Promise<Array<T>>((resolve) => {
      const sortedData = [...data].sort((a: T, b: T) => {
        if (sortCol.sortField != col) {
          return `${a[col as keyof T]}`
            .toLowerCase()
            .localeCompare(`${b[col as keyof T]}`.toLowerCase());
        }

        if (sortCol.type == SortType.DESC) {
          return `${a[col as keyof T]}`
            .toLowerCase()
            .localeCompare(`${b[col as keyof T]}`.toLowerCase());
        }
        return `${b[col as keyof T]}`
          .toLowerCase()
          .localeCompare(`${a[col as keyof T]}`.toLowerCase());
      });
      resolve(sortedData);
    }).then((res: Array<T>) => {
      res && setData(res);
      setSortCol({
        sortField: col,
        type: sortCol.type === SortType.DESC ? SortType.ASC : SortType.DESC,
      });
    });
  };

  useEffect(() => {
    setData(dataSet);
  }, [dataSet]);

  return (
    <div className={`table-container ${className}`}>
      <Table responsive borderless>
        <thead>
          <tr>
            {headers.map(
              (
                { title, onSortPress, sortField }: ListViewHeader,
                index: number
              ) => (
                <th
                  key={index}
                  className="sub__tittle__medium"
                  onClick={
                    sortField
                      ? () => {
                          sortData(sortField);
                        }
                      : undefined
                  }
                >
                  {sortField && (
                    <>
                      <ArrowUpIcon
                        onClick={onSortPress}
                        className={`${
                          sortCol.sortField == sortField &&
                          sortCol.type == SortType.ASC
                            ? 'selected__icon__color'
                            : ''
                        }`}
                      />
                      <ArrowDownIcon
                        onClick={onSortPress}
                        className={`me-1 ${
                          sortCol.sortField == sortField &&
                          sortCol.type == SortType.DESC
                            ? 'selected__icon__color'
                            : ''
                        }`}
                      />
                    </>
                  )}
                  {title}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {totalRow > 0 ? (
            data?.map(renderRowItem)
          ) : (
            <tr>
              <td colSpan={headers.length}>
                <div className="d-flex justify-content-center align-items-center ">
                  <span className="h-50">No item found</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="table-footer body__small">
        <span>Rows per page:</span>
        <select value={currentPage} onChange={onSelectPage}>
          {Array.from<number>({ length: totalPage }).map(
            (value: number, index: number) => (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            )
          )}
        </select>
        <span>{`${startItemOffset()}-${endItemOffset()} of ${totalRow}`}</span>
        <button className={`btn__paging`} onClick={onPrevBtnPress}>
          <ChervonLeft
            className={`${
              currentPage > 1 ? 'enable__icon__color' : 'disable__icon__color'
            }`}
          />
        </button>
        <button className={`btn__paging`} onClick={onNextBtnPress}>
          <ChervonRight
            className={`${
              currentPage < totalPage
                ? 'enable__icon__color'
                : 'disable__icon__color'
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default ListView;
