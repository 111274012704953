import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomModalProps } from 'components/modal/ILAModal';

export interface ILAModalProps {
  children?: React.ReactElement | null;
  type: 'modal' | 'sidebar';
  closeOutSide?: boolean;
  modalProps?: CustomModalProps;
  sideBarPosition?: 'start' | 'end' | 'top' | 'bottom';
  onCloseOutSide?: () => void;
}

interface ModalState {
  isShowModal?: boolean | null;
  props?: ILAModalProps;
}

const initialState: ModalState = {
  isShowModal: false,
};

const ModalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    showModal: (state: ModalState, data: PayloadAction<ILAModalProps>) => {
      state.isShowModal = true;
      state.props = data.payload;
    },
    hideModal: (state: ModalState) => {
      state.isShowModal = false;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action: AnyAction) => (action.type as string).endsWith('logout'),
      (state: ModalState) => {
        state.isShowModal = false;
      }
    );
  },
});

export const { showModal, hideModal } = ModalSlice.actions;
export default ModalSlice;
