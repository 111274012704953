import { SearchIcon } from 'assets/AssetUtil';
import { debounce } from 'lodash';
import React, { ChangeEvent } from 'react';
import './search_input.scss';

export type SearchInputProps = {
  onSearchIconClick?: (textSearch: string) => void;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  onChangeSearchInput?: (txt: string) => void;
};
const SearchInput = ({
  inputProps,
  onChangeSearchInput,
  onSearchIconClick,
}: SearchInputProps) => {
  let searchText = '';
  const debouncEvent = debounce((txt: string) => {
    searchText = txt;
    onChangeSearchInput?.(txt);
  }, 350);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) =>
    debouncEvent(e.target.value);

  return (
    <div className="input__container">
      <input
        {...inputProps}
        className="search__input__style body__medium"
        onChange={onChangeSearch}
      ></input>
      <button onClick={() => onSearchIconClick?.(searchText)}>
        <SearchIcon className="icon__search" />
      </button>
    </div>
  );
};

export default SearchInput;
