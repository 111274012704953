import { OdooContext } from 'models/odooSession.model';

export enum MethodName {
  'read' = 'read', // read record
  'write' = 'write',
  'create' = 'create',
  'unlink' = 'unlink', // delete record
  'search' = 'search_read',
  'onchange' = 'onchange',
}
export interface FilterParam {
  limit?: number;
  offset?: number;
  sort?: string;
  domain?: string;
}

export interface ApiRequestParams {
  model: string;
  method: MethodName;
  with_context?: OdooContext;
  filter?: FilterParam;
  args: Array<unknown>;
}
