import React from 'react';
import Toast from 'components/toast/Toast';
import './toast.scss';
import { useSelector } from 'react-redux';
import { toastsSelect } from 'slices/toastSlice';
import { IToast } from '../../models/toast.model';

const ToastList = () => {
  const toasts = useSelector(toastsSelect);

  return (
    <>
      <div className="toast-list">
        {toasts?.map((toast: IToast, index: number) => (
          <Toast
            key={index}
            index={index}
            title={toast.title}
            type={toast.type}
            content={toast.content}
          />
        ))}
      </div>
    </>
  );
};

export default ToastList;
