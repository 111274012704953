import React, { useCallback, useState } from 'react';
import './toast.scss';
import { IToast, IToastImages, ToastType } from 'models/toast.model';
import { CloseIcon } from 'assets/AssetUtil';
import successfulSVG from '../../assets/icons/toast_successful.svg';
import warningSVG from '../../assets/icons/toast_warning.svg';
import errorSVG from '../../assets/icons/toast_error.svg';
import informationSVG from '../../assets/icons/toast_information.svg';
import defaultSVG from '../../assets/icons/toast_default.svg';

import { useDispatch } from 'react-redux';
import { deleteToast } from 'slices/toastSlice';

const ToastImages: IToastImages = {
  successful: successfulSVG,
  warning: warningSVG,
  error: errorSVG,
  information: informationSVG,
  default: defaultSVG,
};

const START_CLOSE_ANIMATION_TIME = 100;
const CLOSE_ANIMATION_TIME = 300;

const Toast = ({ index, type, title, content }: IToast) => {
  const dispatch = useDispatch();
  const toastType = ToastType[type];
  const image = ToastImages[toastType as keyof IToastImages];
  const [startCloseAnimation, setStartCloseAnimation] = useState(false);
  const [closeAnimation, setCloseAnimation] = useState(false);

  const handleClose = useCallback(() => {
    setTimeout(() => setStartCloseAnimation(true), START_CLOSE_ANIMATION_TIME);
    setTimeout(() => setCloseAnimation(true), CLOSE_ANIMATION_TIME);
    dispatch(deleteToast(index));
  }, [dispatch, index]);

  return (
    <>
      <div
        style={{ display: `${closeAnimation ? 'none' : ''}` }}
        className={`${startCloseAnimation} toast toast__${toastType} toast__${
          startCloseAnimation ? 'close' : 'open'
        }`}
      >
        <div className="toast__left">
          <img className="toast__left__badge" src={image} />
          <div className="toast__left__info">
            <h5 className="toast__left__info__title">{title}</h5>
            <p className="toast__left__info__content">{content}</p>
          </div>
        </div>

        <div className="toast__right">
          <CloseIcon className="toast__right__icon" onClick={handleClose} />
        </div>
      </div>
    </>
  );
};

export default Toast;
