import {
  ApiResponseV2,
  OnChangeResponse,
  requestURLV2,
} from 'models/base_response/oddoApiResponseV2.model';
import {
  ApiRequestParams,
  MethodName,
} from 'models/request/api_request_params.model';
import { useCallback, useMemo, useRef } from 'react';
import apiClient from './apiClient';
import store from 'store';

const baseFetchApiOddo = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    callApiOddo: builder.query<ApiResponseV2, ApiRequestParams>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        method: 'POST',
        url: `/${requestURLV2}`,
        body: {
          params: {
            ...params,
            with_context: store.getState().auth.odooSession?.context,
          },
        },
      }),
    }),
  }),
});

export const { useLazyCallApiOddoQuery } = baseFetchApiOddo;

const useFetchOddoApi = () => {
  const requestParam = useRef<ApiRequestParams | null>(null);

  const [
    request,
    { data: response, isFetching, isLoading, isSuccess, error: apiError },
  ] = useLazyCallApiOddoQuery();

  const fetchApi = useCallback(
    (params: ApiRequestParams) => {
      requestParam.current = params;
      request(params);
    },
    [request]
  );

  return useMemo(() => {
    return [
      {
        ...response,
        isFetching,
        isLoading,
        isSuccess,
        errorServer: apiError,
      },
      fetchApi,
    ] as const;
  }, [response, isFetching, isLoading, isSuccess, apiError, fetchApi]);
};

const useOnChangeData = () => {
  const [data, fetchApi] = useFetchOddoApi();
  const { data: onChangeData, ...state } = data;
  const onChange = useCallback(
    ({
      model,
      param,
      id,
      fieldName,
    }: {
      model: string;
      id?: number;
      param?: object;
      fieldName?: string;
    }) => {
      const recordId = id ? [id] : [];
      const params: ApiRequestParams = {
        model: model,
        method: MethodName.onchange,
        args: [recordId, param, `${fieldName}`],
      };
      fetchApi(params);
    },
    [fetchApi]
  );

  return [
    {
      data: (onChangeData?.obj as OnChangeResponse) || undefined,
      ...state,
    },
    onChange,
  ] as const;
};

export { useFetchOddoApi, useOnChangeData };
