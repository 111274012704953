import SkeletonBox from 'components/skeleton_box/SkeletonBox';

const SkeletonDrawerBar = () => {
  return (
    <>
      <div
        style={{ backgroundColor: '#fafafa', borderRadius: '1rem' }}
        className="w-100 h-100 d-flex flex-column justify-content-between py-4 px-3"
      >
        <div className="d-flex justify-content-center align-items-center">
          <SkeletonBox w="80px" h="60px" rounded="1rem" />
        </div>
        <div>
          <SkeletonBox w="100%" h="30px" rounded="2px" className="mb-4" />
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
        </div>
        <div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
          <div className="d-flex mb-4">
            <SkeletonBox w="1rem" h="1rem" rounded="2px" className="me-1" />
            <SkeletonBox w="100%" h="20px" rounded="1rem" />
          </div>
        </div>
      </div>
    </>
  );
};
export default SkeletonDrawerBar;
