import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
// import { ResumeProps } from '../../models/profileResponse.model';
import {
  imageBase64WithoutPrefix,
  stringToImageBase64,
} from '../../utils/helpers';
import {
  UserIcon,
  PayrollIcon,
  BankIcon,
  ContractIcon,
  ShieldIcon,
  CameraIcon,
} from 'assets/AssetUtil';
import './profile.scss';
import { profileSelect } from 'slices/profileSlice';
// import moment from 'moment';
import Box from 'components/box/Box';
import { Form, Col, Row } from 'react-bootstrap';
// import EditIcon from 'components/edit-icon/EditIcon';
import ILAInput from 'components/input/Input';
import { IUserData } from './profileJson.model';
// import ILaButton from 'components/button/ILaButton';
import Resizer from 'react-image-file-resizer';
import { ProileJSON } from './profileJson';
import { UserEditProfile } from 'models/profileResponse.model';
// import { useUpdateProfile } from 'api/userApi';
// import { HTTP_STATUS } from 'models/http-status.model';

export interface openEditProfilePros {
  general: boolean;
  personal: boolean;
  emergency: boolean;
  documents: boolean;
  address: boolean;
  bank: boolean;
}

const Profile: React.FC = () => {
  const userInfo = useSelector(profileSelect)?.userProfile;

  // const [data, updateApiProfile] = useUpdateProfile();
  // const { message, code } = data || {};

  // state edit for page
  const [openEditProfile, setOpenEditProfile] = useState({
    general: false,
    personal: false,
    emergency: false,
    documents: false,
    address: false,
    bank: false,
  });

  // Start ----- state for upload avatar
  // const dispatch = useDispatch();
  const [messageInfo] = useState('Valid');
  const [isError] = useState('');

  // For Save Action
  // useEffect(() => {
  //   if (code == HTTP_STATUS.SUCCESS) {
  //     setMessageInfo('Update avatar image success');
  //     // setIsShowIcon(true);
  //     setIsError('success');
  //     if (userInfo) {
  //       dispatch(
  //         updateProfile({
  //           ...userInfo,
  //           ['image_1920' as keyof UserEditProfile]: selectedImage,
  //         })
  //       );
  //     }
  //   } else if (message) {
  //     setMessageInfo(message);
  //     // setIsShowIcon(true);
  //     setIsError('error');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message, code, dispatch]);

  // End ----- state for upload avatar

  // Close box reset state edit category
  const setIsEditCategory = (
    category: string,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // e.preventDefault();
    e && e.stopPropagation();
    // setIsEditProfile(true);
    setOpenEditProfile({
      general: false,
      personal: false,
      emergency: false,
      documents: false,
      address: false,
      bank: false,
      [category]: true,
    });
  };

  // UI Box --- edit data profile
  const generalLeftInfo: Array<ReactElement> = [];
  const generalRightInfo: Array<ReactElement> = [];
  const personalLeftInfo: Array<ReactElement> = [];
  const personalRightInfo: Array<ReactElement> = [];
  const emergencyContact: Array<ReactElement> = [];
  const addressInfo: Array<ReactElement> = [];
  const documentsInfo: Array<ReactElement> = [];
  const bankInfo: Array<ReactElement> = [];

  // Render Box by UI
  // Generate Dynamic Form From JSON
  const profileData: Array<IUserData> = ProileJSON;
  profileData.forEach(({ category, position, name, key, content }, index) => {
    if (category == 'emergency') {
      console.log('xxxx', userInfo?.partner_relationship_ids?.value);
      Array.isArray(userInfo?.partner_relationship_ids?.value) &&
        userInfo?.partner_relationship_ids?.value.forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any, index) => {
            console.log('item', item);
            const tmp = (
              <>
                <Box
                  title="Emergency Contact"
                  iconLeft={<ContractIcon />}
                  className="profile-item cursor-pointer ila-form-content mb-4"
                  onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    setIsEditCategory('emergency', e)
                  }
                >
                  <Form.Group
                    as={Row}
                    className="ila-profile-des edit-form d-flex align-items-start justify-content-center"
                    key={String(index + category + key)}
                  >
                    <Form.Label column sm={4} className="sub__tittle__small">
                      Name
                    </Form.Label>
                    <Col sm={8}>
                      <div className="form-text-edit">{item?.display_name}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="ila-profile-des edit-form d-flex align-items-start justify-content-center"
                    key={String(index + category + key)}
                  >
                    <Form.Label column sm={4} className="sub__tittle__small">
                      Relationship
                    </Form.Label>
                    <Col sm={8}>
                      <div className="form-text-edit">
                        {item?.relationship_id[1]}
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="ila-profile-des edit-form d-flex align-items-start justify-content-center"
                    key={String(index + category + key)}
                  >
                    <Form.Label column sm={4} className="sub__tittle__small">
                      Mobile
                    </Form.Label>
                    <Col sm={8}>
                      <div className="form-text-edit">{item?.mobile}</div>
                    </Col>
                  </Form.Group>
                </Box>
              </>
            );
            emergencyContact.push(tmp);
          }
        );
    } else {
      content = userInfo?.[key as keyof UserEditProfile] ?? '';
      const tmp = (
        <>
          <Form.Group
            as={Row}
            className="ila-profile-des edit-form d-flex align-items-start justify-content-center"
            key={String(index + category + key)}
          >
            <Form.Label column sm={4} className="sub__tittle__small">
              {name}
            </Form.Label>
            <Col sm={8}>
              <ILAInput
                parameter={key}
                category={category}
                edit={openEditProfile[category as keyof openEditProfilePros]}
                index={index}
                valueInput={content}
                // functionApi={useUpdateProfile()}
                onRefresh={(valueChange) => {
                  content = valueChange;
                  console.log('onRefresh-----', content, valueChange);
                }}
              />
            </Col>
          </Form.Group>
        </>
      );

      switch (category) {
        case 'general':
          if (position == 'left') {
            generalLeftInfo.push(tmp);
          } else generalRightInfo.push(tmp);
          break;
        case 'personal':
          if (position == 'left') {
            personalLeftInfo.push(tmp);
          } else personalRightInfo.push(tmp);
          break;
        // case 'emergency':
        //   emergencyContact.push(tmp);
        //   break;
        case 'address':
          addressInfo.push(tmp);
          break;
        case 'documents':
          documentsInfo.push(tmp);
          break;
        case 'bank':
          bankInfo.push(tmp);
          break;
        default:
          break;
      }
    }
  });
  console.log('profileData --- userInfo', profileData);

  const [selectedImage, setSelectedImage] = useState(
    userInfo?.image_1920?.value
  );

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target?.files?.[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          'png',
          100,
          0,
          async (uri) => {
            // Request will be sent from here in the future
            if (uri) {
              setSelectedImage(imageBase64WithoutPrefix(uri as string));
              // updateApiProfile(userInfo?.id || 0, {
              //   ['image_1920' as keyof UserEditProfile]: selectedImage,
              // });
            }
          },
          'base64',
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div
        className="profile-container"
        onClick={() => {
          setOpenEditProfile({
            general: false,
            personal: false,
            emergency: false,
            documents: false,
            address: false,
            bank: false,
          });
        }}
      >
        <Form>
          <Row>
            <div className="col-xs-12 col-md-12 col-lg-12">
              <Box
                title="General Information"
                iconLeft={<PayrollIcon />}
                className="profile-item cursor-pointer ila-form-content"
                onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  setIsEditCategory('general', e);
                }}
              >
                <div className="row card-info-item">
                  <div className="block-avatar d-flex align-items-center justify-content-center col-lg-2 col-md-12 col-xs-12 ">
                    <img
                      src={stringToImageBase64(selectedImage)}
                      alt={userInfo?.name?.value}
                      className={`avatar rounded-circle input-${isError ?? ''}`}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                      className="upload"
                    />
                    <div className="avatar-mask">
                      <CameraIcon />
                    </div>
                    {isError && (
                      <div className={`tooltip tooltip-${isError}`}>
                        <p className="sub__tittle__small">{messageInfo}</p>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-10 col-md-12 col-xs-12">
                    <Row>
                      <div className="col-xs-12 col-md-6">
                        <div className="ila-form-content">
                          {generalLeftInfo}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className="ila-form-content">
                          {generalRightInfo}
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Box>
            </div>
            <div className="mt-4 col-xs-12 col-md-12 col-lg-12">
              <Box
                title="Personal Information"
                backgroundColor="#FBF0E2"
                iconLeft={<UserIcon />}
                className="h-100 profile-item cursor-pointer ila-form-content"
                onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  setIsEditCategory('personal', e);
                }}
              >
                <Row>
                  <div className="col-xs-12 col-md-6">{personalLeftInfo}</div>
                  <div className="col-xs-12 col-md-6">{personalRightInfo}</div>
                </Row>
              </Box>
            </div>

            <div className="mt-4 card-info-item col-xs-12 col-md-6 col-lg-7 ">
              {/* // box work experience here */}
              <Box
                title="Address"
                backgroundColor="#FBF0E2"
                iconLeft={<PayrollIcon />}
                className="h-100 profile-item cursor-pointer ila-form-content"
                onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  setIsEditCategory('address', e)
                }
              >
                {addressInfo}
              </Box>
            </div>
            <div className=" mt-4 card-info-item col-xs-12 col-md-6 col-lg-5">
              {emergencyContact}
              <Box
                title="Bank Information"
                backgroundColor="#fef3fb"
                iconLeft={<BankIcon />}
                className="profile-item cursor-pointer ila-form-content"
                onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  setIsEditCategory('bank', e)
                }
              >
                <div className="">{bankInfo}</div>
              </Box>
            </div>
            <div className="mt-4 card-info-item col-xs-12 col-md-12 col-lg-12">
              <Box
                title="Documents"
                iconLeft={<ShieldIcon />}
                className="mb-4 profile-item cursor-pointer ila-form-content"
                onClick={(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  setIsEditCategory('documents', e)
                }
              >
                <Row>
                  <div className="col-xs-12 col-md-6">
                    <div className="ila-form-content">{documentsInfo}</div>
                  </div>
                </Row>
              </Box>
            </div>
          </Row>
        </Form>
        {/* {orgChart} */}
      </div>
    </>
  );
};

export default Profile;
