import React from 'react';
import './pop-up-message.scss';

import {
  PopUpMessageSuccessfulIcon,
  PopUpMessageWarningIcon,
  PopUpMessageErrorIcon,
  PopUpMessageInformationIcon,
} from 'assets/AssetUtil';
import { PopUpMessageProps, PopUpMessageType } from 'models/popUpMessage.model';
import ILAButton from 'components/button/CustomButton';
import { useDispatch } from 'react-redux';
import { hideModal } from 'slices/modalSlice';

const PopUpMessageInfo = {
  [PopUpMessageType.SUCCESSFUL]: {
    btnTitle: 'OK',
    popupTitle: 'Successful',
    icon: <PopUpMessageSuccessfulIcon />,
  },
  [PopUpMessageType.WARNING]: {
    btnTitle: 'OK',
    popupTitle: 'Warning',
    icon: <PopUpMessageWarningIcon />,
  },
  [PopUpMessageType.ERROR]: {
    btnTitle: 'Close',
    popupTitle: 'Error',
    icon: <PopUpMessageErrorIcon />,
  },
  [PopUpMessageType.INFORMATION]: {
    btnTitle: 'OK',
    popupTitle: 'Information',
    icon: <PopUpMessageInformationIcon />,
  },
};

const PopUpMessage = ({
  type,
  content,
  isShowCancelBtn,
  onActionPress,
  onCancelPress,
}: PopUpMessageProps) => {
  const dispatch = useDispatch();
  const { btnTitle, icon, popupTitle } = PopUpMessageInfo[type];

  const cancelPopup = () => {
    dispatch(hideModal());
    onCancelPress && onCancelPress();
  };

  const onBtnMainActionClick = () => {
    dispatch(hideModal());
    onActionPress && onActionPress();
  };
  return (
    <>
      <div
        className={`popup__message__container pop-up-message__${type}__style`}
      >
        <div className="popup__icon">{icon}</div>
        <h2 className={`mt-3 title__medium pop-up-message__${type}__title`}>
          {popupTitle}
        </h2>
        <p className="body__small">{content}</p>

        <div className="d-flex w-100">
          {isShowCancelBtn && (
            <ILAButton onPress={cancelPopup} className="me-3">
              Cancel
            </ILAButton>
          )}
          <ILAButton
            btnType="primary"
            onPress={onBtnMainActionClick}
            className="btn__action"
          >
            {btnTitle}
          </ILAButton>
        </div>
      </div>
    </>
  );
};

export default PopUpMessage;
