import { useApproveLeave, useFetchApprovalLeaves } from 'api/leaveApi';
import { TickIcon } from 'assets/AssetUtil';
import { FilterItem } from 'components/filter/DropdonwFilter';
import { FilterGroupMemo } from 'components/filter_button/FilterButton';
import ListView from 'components/list_view/ListView';
import PopUpMessage from 'components/popup_message/PopUpMessage';
import SearchInput from 'components/search_input/SearchInput';
import { HTTP_STATUS } from 'models/http-status.model';
import { LeaveFilterParams, LeaveRecord } from 'models/leavesResponse.model';
import { PopUpMessageType } from 'models/popUpMessage.model';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelect } from 'slices/authSlice';
import { showModal } from 'slices/modalSlice';
import { leaveSlice, setApprovalLeaves } from 'slices/userLeaveSlice';
import './approval.scss';

const ApprovalLeave = () => {
  const dispatch = useDispatch();
  const filterParams = useRef<LeaveFilterParams>({});
  const [response, getApprovalLeave] = useFetchApprovalLeaves();
  const [responseApproval, approveLeave] = useApproveLeave();

  const userId = useSelector(authSelect).odooSession?.context?.uid;

  const { data, code } = response || {};

  const approvalLeave = useSelector(leaveSlice).aprrovalLeaves;

  const getLeaveByPage = useCallback(
    ({ offset = 0, filters }: { offset?: number; filters?: object }) => {
      getApprovalLeave({
        id: userId,
        offset: offset,
        limit: 50,
        filterParams: filters,
      });
    },
    [getApprovalLeave, userId]
  );

  const onApproveLeave = (ids: Array<number>) => {
    approveLeave(ids);
  };

  useEffect(() => {
    const { code, isFetching } = responseApproval;
    if (!isFetching) {
      if (code && code != 200) {
        dispatch(
          showModal({
            type: 'modal',
            closeOutSide: true,
            modalProps: {
              centered: true,
            },
            children: (
              <PopUpMessage
                type={PopUpMessageType.ERROR}
                content={responseApproval.message}
              />
            ),
          })
        );
      } else if (code == 200) {
        dispatch(
          showModal({
            type: 'modal',
            closeOutSide: true,
            modalProps: {
              centered: true,
            },
            children: (
              <PopUpMessage
                type={PopUpMessageType.SUCCESSFUL}
                content={responseApproval.message}
              />
            ),
          })
        );
        getLeaveByPage({ filters: filterParams.current });
      }
    }
  }, [dispatch, getLeaveByPage, responseApproval]);

  useEffect(() => {
    if (code == HTTP_STATUS.SUCCESS && data?.obj) {
      dispatch(setApprovalLeaves(data?.obj as Array<LeaveRecord>));
    }
  }, [code, data, dispatch]);

  useEffect(() => {
    getLeaveByPage({ offset: 0 });
  }, [getApprovalLeave, getLeaveByPage, userId]);

  const fetchApiByFilter = (filter: FilterItem) => {
    filterParams.current = {
      ...filterParams.current,
      [filter.key as keyof LeaveFilterParams]: filter.value,
    };

    getLeaveByPage({
      filters: filterParams.current,
    });
  };

  return (
    <div className="d-flex flex-column appvoalLeave__container">
      <div className="d-flex flex-row">
        <FilterGroupMemo
          items={[
            {
              key: 'state',
              value: 'confirm',
              displayTitle: 'To Aprrove',
            },
            {
              key: 'state',
              value: 'validate',
              displayTitle: 'Approved',
            },
          ]}
          key="1234567"
          onClickItem={fetchApiByFilter}
        />
        <div className="search__container">
          <SearchInput
            inputProps={{
              placeholder: 'Search empployee name',
            }}
            onSearchIconClick={() =>
              getLeaveByPage({
                filters: filterParams.current,
              })
            }
            onChangeSearchInput={(input: string) => {
              console.log(input);
              filterParams.current = {
                ...filterParams.current,
                employee_id: input,
              };
              getLeaveByPage({
                filters: filterParams.current,
              });
            }}
          />
        </div>
      </div>
      <ListView
        totalRow={data?.total || 0}
        itemCountPerPage={50}
        onNextPageClick={(offset: number) =>
          getLeaveByPage({ offset: offset, filters: filterParams.current })
        }
        onPrevPageClick={(offset: number) =>
          getLeaveByPage({ offset: offset, filters: filterParams.current })
        }
        headers={[
          {
            title: 'Employee',
          },
          {
            title: 'Leave Type',
            sortField: 'holiday_status_id',
          },
          {
            title: 'From',
          },
          {
            title: 'To',
          },
          {
            title: 'Duration',
          },
          {
            title: 'Reason',
          },
          {
            title: 'Status',
          },
          {
            title: 'Actions',
          },
        ]}
        dataSet={approvalLeave || []}
        renderRowItem={(item: LeaveRecord) => {
          return (
            <tr key={item.id}>
              <td style={{ width: '15%' }}>
                {item.employee_id?.[1] as string}
              </td>

              <td style={{ width: '15%' }}>{item.holiday_status_id?.[1]}</td>
              <td style={{ width: '10%' }}>{item.date_from}</td>
              <td style={{ width: '10%' }}>{item.date_to}</td>
              <td style={{ width: '10%' }}>{item.duration_display}</td>
              <td style={{ width: '25%' }}>{item.name}</td>
              <td style={{ width: '20%' }}>{item.state?.[1]}</td>
              <td>
                {item.state?.[0] == 'confirm' && (
                  <div className="d-flex">
                    <button
                      className="btn_accept"
                      onClick={() => onApproveLeave([item.id])}
                    >
                      <TickIcon />
                    </button>
                  </div>
                )}
              </td>
            </tr>
          );
        }}
      />
    </div>
  );
};

export default ApprovalLeave;
