import AuthSlice from './authSlice';
import ModalSlice from './modalSlice';
import ToastSlice from './toastSlice';
import UserLeaveSlice from './userLeaveSlice';
import ProfileSlice from './profileSlice';
import AppSlice from './appSlice';
import LoadingSlice from './loadingSlice';

export default {
  auth: AuthSlice.reducer,
  modal: ModalSlice.reducer,
  toast: ToastSlice.reducer,
  userLeave: UserLeaveSlice.reducer,
  profile: ProfileSlice.reducer,
  route: AppSlice.reducer,
  loading: LoadingSlice.reducer,
};
