export enum ToastType {
  'successful',
  'information',
  'warning',
  'default',
  'error',
}
export interface IToast {
  type: ToastType;
  index?: number;
  title?: string;
  content?: string;
  isClose?: boolean;
}

export interface IToastSlice {
  open?: boolean;
  toasts?: IToast[];
}

export interface IToastImages {
  successful: string;
  warning: string;
  error: string;
  information: string;
  default: string;
}
