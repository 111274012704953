import {
  ApiRequestParams,
  MethodName,
} from 'models/request/api_request_params.model';
import { useCallback } from 'react';
import { useFetchOddoApi } from './base/apiOdoo';

export type EditProfileParam = {
  mobile_phone?: string;
  work_email?: string;
  gender?: string;
  marital?: string;
};

const useGetProfile = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const getProfile = useCallback(
    (eid: number) => {
      const params: ApiRequestParams = {
        model: 'hr.employee',
        method: MethodName.read,
        args: [
          [eid],
          [
            'id',
            'code',
            'name',
            'job_title_id',
            'join_date',
            'mobile_phone',
            'work_email',
            'image_1920',
            'birthday',
            'department_id',
            'gender',
            'line_manager_name',
            'line_manager_avatar',
            'work_phone',
            'native_country_id',
            'emergency_contact',
            'emergency_phone',
            'resume_line_ids',
            'bank_name',
            'bank_acc_number',
            'bank_acc_holder',
            'partner_relationship_ids',
            'identification_id_number',
            'identification_id_issue_date',
            'passport_id_number',
            'passport_id_issue_date',
            'marital',
            'permit_no',
            'permit_expire',
            'religion_id',
            'residential_street',
            'residential_ward_id',
            'residential_district_id',
            'residential_state_id',
            'residential_country_id',
            'permanent_street',
            'permanent_ward_id',
            'permanent_district_id',
            'permanent_state_id',
            'permanent_country_id',
            'company_id',
            'nationality_id',
            'phone',
            'private_email',
            'partner_id',
            // 'account_holder_name',
            // 'emergency_relationship',
          ],
        ],
        filter: {
          limit: 50,
          offset: 0,
          sort: '',
          domain: '',
        },
      };
      fetchApi(params);
    },
    [fetchApi]
  );
  return [data, getProfile] as const;
};

const useUpdateProfile = () => {
  // const [data, fetchApi] = useFetchOddoApi();

  // const updateProfile = useCallback(
  //   (userId: number, input: EditProfileParam) => {
  //     // const params = {
  //     //   model_name: 'hr.employee',
  //     //   method_name: MethodName.write,
  //     //   data: {
  //     //     ids: [userId],
  //     //     values: input,
  //     //   },
  //     // };
  //     // fetchApi(params);
  //     console.log('first');
  //   },
  //   [fetchApi]
  // );
  // return [data, updateProfile] as const;
  console.log('first');
};

export { useUpdateProfile, useGetProfile };
