import React from 'react';
import { Col, Container } from 'react-bootstrap';
import NotificationItem from './NotificationItem';
import { TickIcon } from 'assets/AssetUtil';

import './notification.scss';

interface NotificationModel {
  title: string;
}

const fakeNotifications: Array<NotificationModel> = [
  {
    title: 'MIS meeting: at 3pm 24.05',
  },
  {
    title: 'Interview Internship',
  },
  {
    title: 'Odoo meetup:',
  },
];

const Notification = () => {
  return (
    <Container className="bg-white p-3 notification__container">
      <Col className="d-flex p-0 justify-content-between align-items-center">
        <h4 className="d-inline notification__title">Notification</h4>

        <h6 className="body__very__small">
          <TickIcon className="icon__mark-read" />
          Mark all as read
        </h6>
      </Col>
      {fakeNotifications.map((item: NotificationModel) => {
        return <NotificationItem {...item} />;
      })}
    </Container>
  );
};

export default Notification;
