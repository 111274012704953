import { RouteInfo } from 'models/routeInfo';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from 'slices/modalSlice';
import Box from 'components/box/Box';
import './submenu.scss';
import { ChervonRight } from 'assets/AssetUtil';

export type SubMenuProps = {
  routeInfo: RouteInfo;
};

const SubMenu: React.FC<SubMenuProps> = ({ routeInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onItemClick = (index: number) => {
    const onItemBoxClick = (item: RouteInfo) => {
      navigate(
        `${routeInfo.path}/${routeInfo?.children?.[index].path}/${item.path}`
      );
      dispatch(hideModal());
    };
    const menuLevel3 = routeInfo?.children?.[index]?.children;
    if (menuLevel3 && menuLevel3.length > 0) {
      // show dialog for level 3
      dispatch(
        showModal({
          type: 'modal',
          closeOutSide: true,
          children: (
            <Box
              title={routeInfo.children?.[index].title}
              className="menu-modal"
            >
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                {routeInfo.children?.[index].children?.map(
                  (item: RouteInfo) => {
                    const Icon = item.icon;
                    return (
                      <div
                        className="d-flex flex-column align-items-center justify-content-center text-center box-sub-menu"
                        key={item.path}
                        onClick={() => onItemBoxClick(item)}
                      >
                        {Icon && typeof Icon != 'string' && (
                          <Icon className="menu-icon-box" />
                        )}
                        <span className="body__medium text-box">
                          {item.title}
                          {/* {routeInfo.path}/{routeInfo?.children?.[index].path}/
                          {item.path} */}
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            </Box>
          ),
          modalProps: {
            size: 'lg',
            centered: true,
          },
        })
      );

      return;
    }

    navigate(`${routeInfo.path}/${routeInfo?.children?.[index].path}`);
  };

  return (
    <>
      {routeInfo?.children?.map((item: RouteInfo, index: number) => {
        return (
          <div
            className={`d-flex align-items-center justify-content-between sub-menu-item ${
              window.location.pathname.split('/')[1] == routeInfo.path &&
              window.location.pathname.split('/')[2] == item.path
                ? 'sub-menu-selected'
                : ''
            }`}
            onClick={() => onItemClick(index)}
            key={index + 1}
          >
            {window.location.pathname == '/' + routeInfo.path + '/' + item.path}
            <span className="body__large">{item.title}</span>
            {item.children && item.children.length > 0 && (
              <ChervonRight className="icon-toggle" />
            )}
          </div>
        );
      })}
    </>
  );
};

export default memo(SubMenu);
