import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OdooSession } from 'models/odooSession.model';
import { RootState } from '../store';

export enum AuthStatus {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

interface AuthState {
  odooSession?: OdooSession;
  authStatus: AuthStatus;
}

const initialState: AuthState = {
  authStatus: AuthStatus.UNAUTHORIZED,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    updateOdooSession: (
      state: AuthState,
      { payload }: PayloadAction<OdooSession | undefined>
    ) => {
      state.odooSession = payload;
    },
    updateAuthStatus: (
      state: AuthState,
      { payload }: PayloadAction<AuthStatus>
    ) => {
      state.authStatus = payload;
    },
    logout: () => {
      handleLogout();
      return initialState;
    },
  },
});

const handleLogout = async () => {
  localStorage.clear();
  const params: RequestInit = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      params: {},
    }),
    credentials: 'include',
  };

  await fetch(`${process.env.REACT_APP_BASE_URL}/web/logout_react`, params);
};
export const { updateOdooSession, logout, updateAuthStatus } =
  AuthSlice.actions;

export const authSelect = (state: RootState) => state.auth;
export default AuthSlice;
