import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaveRecord, LeaveType } from 'models/leavesResponse.model';
import { RootState } from 'store';

interface UserLeaveState {
  myLeaves?: Array<LeaveRecord>;
  aprrovalLeaves?: Array<LeaveRecord>;
  leaveTypes: Array<LeaveType>;
}

const initialState: UserLeaveState = {
  leaveTypes: [],
};

const UserLeaveSlice = createSlice({
  name: 'user_leaves',
  initialState: initialState,
  reducers: {
    setMyLeaves: (
      state: UserLeaveState,
      payload: PayloadAction<Array<LeaveRecord>>
    ) => {
      state.myLeaves = payload.payload;
    },
    setApprovalLeaves: (
      state: UserLeaveState,
      payload: PayloadAction<Array<LeaveRecord>>
    ) => {
      state.aprrovalLeaves = payload.payload;
    },
    removeLeaveItem: (
      state: UserLeaveState,
      { payload }: PayloadAction<number>
    ) => {
      state.myLeaves?.splice(payload, 1);
    },
    setLeaveType: (
      state: UserLeaveState,
      payload: PayloadAction<Array<LeaveType>>
    ) => {
      state.leaveTypes = payload.payload;
    },
  },
});

export const { setMyLeaves, setApprovalLeaves, setLeaveType, removeLeaveItem } =
  UserLeaveSlice.actions;

export const leaveSlice = (state: RootState) => state.userLeave;
export default UserLeaveSlice;
