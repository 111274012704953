import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RouteInfo } from 'models/routeInfo';
import { RootState } from 'store';
interface AppState {
  route?: RouteInfo;
}

const initialState: AppState = {};
const AppSlice = createSlice({
  name: 'app_slice',
  initialState: initialState,
  reducers: {
    currentRoute: (state: AppState, payload: PayloadAction<RouteInfo>) => {
      state.route = payload.payload;
    },
  },
});

export const appSelect = (state: RootState) => state.route;
export default AppSlice;
