/* eslint-disable @typescript-eslint/no-unused-vars */
import { LeaveFilterParams } from 'models/leavesResponse.model';
import { AddLeaveParams } from 'models/request/add_leave_params.model';
import {
  MethodName,
  ApiRequestParams,
} from 'models/request/api_request_params.model';
import { useCallback } from 'react';
import { useFetchOddoApi, useOnChangeData } from './base/apiOdoo';

const useFetchMyLeaves = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const getLeaves = useCallback(
    ({
      id,
      offset = 0,
      limit = 50,
    }: {
      id?: number;
      offset?: number;
      limit?: number;
    }) => {
      const params: ApiRequestParams = {
        model: 'hr.leave',
        method: MethodName.search,
        args: [
          'id',
          'holiday_status_id',
          'date_from',
          'date_to',
          'duration_display',
          'name',
          'state',
        ],
        filter: {
          sort: '',
          offset: offset,
          limit: limit,
          domain: `[('employee_id','=', ${id})]`,
        },
      };

      fetchApi(params);
    },
    [fetchApi]
  );
  return [data, getLeaves] as const;
};

const useDeleteLeaves = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const deleteLeaveById = useCallback((id: number) => {
    // const params: ApiRequestParams = {
    //   model: 'hr.leave',
    //   method: MethodName.unlink,
    //   data: { ids: [id] },
    // };
    // fetchApi(params);
    console.log('first');
  }, []);
  return [data, deleteLeaveById] as const;
};

const useCreatUserLeave = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const createNewLeave = useCallback(
    (data: object) => {
      const params: ApiRequestParams = {
        model: 'hr.leave',
        method: MethodName.create,
        args: [data],
      };
      fetchApi(params);
    },
    [fetchApi]
  );
  return [data, createNewLeave] as const;
};

const useGetLeaveSeletion = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const getLeaveSelection = useCallback(() => {
    const params: ApiRequestParams = {
      model: 'hr.leave.type',
      method: MethodName.search,
      args: ['display_name', 'id'],
      filter: {
        limit: 50,
        offset: 0,
        sort: '',
        domain: '',
      },
    };
    fetchApi(params);
    console.log('first');
  }, [fetchApi]);
  return [data, getLeaveSelection] as const;
};

const useApproveLeave = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const approveLeave = useCallback(
    (ids: Array<number>) => {
      const params: ApiRequestParams = {
        model: 'hr.leave',
        method: MethodName.write,
        args: [ids, { state: 'validate' }],
      };
      fetchApi(params);
    },
    [fetchApi]
  );
  return [data, approveLeave] as const;
};

const useOnChangeLeave = () => {
  const [data, onChangeData] = useOnChangeData();

  const onChange = useCallback(
    ({ param, field: filedName }: { param?: object; field?: string }) => {
      onChangeData({
        model: 'hr.leave',
        param: param,
        fieldName: filedName,
      });
    },
    [onChangeData]
  );
  return [data, onChange] as const;
};

const useFetchApprovalLeaves = () => {
  const [data, fetchApi] = useFetchOddoApi();

  const getApprovalLeaves = useCallback(
    ({
      id,
      filterParams,
      offset = 0,
      limit = 10,
    }: {
      id?: number;
      offset?: number;
      limit?: number;
      filterParams?: LeaveFilterParams;
    }) => {
      const filterStrs = [`('first_approval_user_id','=', ${id})`];

      if (filterParams) {
        Object.keys(filterParams || {})?.forEach((key: string) =>
          filterStrs.push(
            `('${key}','=', '${
              filterParams?.[key as keyof LeaveFilterParams]
            }')`
          )
        );
      }

      const params: ApiRequestParams = {
        model: 'hr.leave',
        method: MethodName.search,
        args: [
          'id',
          'employee_id',
          'holiday_status_id',
          'date_from',
          'date_to',
          'duration_display',
          'name',
          'state',
        ],
        filter: {
          limit: limit,
          offset: offset,
          domain: `[${filterStrs.toString()}]`,
        },
      };

      fetchApi(params);
    },
    [fetchApi]
  );
  return [data, getApprovalLeaves] as const;
};

export {
  useFetchMyLeaves,
  useApproveLeave,
  useDeleteLeaves,
  useCreatUserLeave,
  useGetLeaveSeletion,
  useFetchApprovalLeaves,
  useOnChangeLeave,
};
