import { IUserData } from 'pages/profile/profileJson.model';
export const HomeJSON: Array<IUserData> = [
  {
    category: 'general',
    position: 'left',
    name: 'Full name',
    key: 'name',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Job title',
    key: 'job_title_id',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Phone',
    key: 'mobile_phone',
    content: '',
  },
  {
    category: 'general',
    position: 'left',
    name: 'Email',
    key: 'work_email',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Company',
    key: 'company_id',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Department',
    key: 'department_id',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Join date',
    key: 'join_date',
    content: '',
  },
  {
    category: 'general',
    position: 'right',
    name: 'Line manager',
    key: 'line_manager_name',
    content: '',
  },
];
