import { useFetchMyLeaves, useGetLeaveSeletion } from 'api/leaveApi';
import ILAButton from 'components/button/CustomButton';
import ListView from 'components/list_view/ListView';
import { LeaveRecord, LeaveType } from 'models/leavesResponse.model';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelect } from 'slices/authSlice';
import { showModal } from 'slices/modalSlice';
import { leaveSlice, setLeaveType, setMyLeaves } from 'slices/userLeaveSlice';
import { formatDate } from 'utils/helpers/DateUtils';
import BookingLeaveForm from './BookingLeaveForm';
import './user_leave.scss';

const UserLeave = () => {
  const dispatch = useDispatch();
  const [response, getLeaves] = useFetchMyLeaves();
  const [leaveTypesResponse, getLeaveTypes] = useGetLeaveSeletion();

  const eId = useSelector(authSelect).odooSession?.eid;

  const { data, code } = response || {};

  const myLeaves = useSelector(leaveSlice).myLeaves;

  const createLeaveForm = () => {
    dispatch(
      showModal({
        children: (
          <BookingLeaveForm
            onRefresh={() => {
              getLeaves({ id: eId });
            }}
          />
        ),
        type: 'modal',
        modalProps: {
          size: 'xl',
          centered: true,
        },
      })
    );
  };

  const getLeaveByPage = useCallback(
    (offset: number) => {
      console.log('offsetttt');
      console.log(offset);
      getLeaves({ id: eId, offset, limit: 50 });
    },
    [getLeaves, eId]
  );

  useEffect(() => {
    if (code == 200 && data?.obj) {
      dispatch(setMyLeaves(data?.obj as Array<LeaveRecord>));
    }
  }, [code, data, dispatch]);

  useEffect(() => {
    if (leaveTypesResponse.data) {
      const leaveTypes = leaveTypesResponse.data.obj as Array<LeaveType>;
      leaveTypes && dispatch(setLeaveType(leaveTypes));
    }
  }, [dispatch, leaveTypesResponse]);

  useEffect(() => {
    getLeaveByPage(0);
    getLeaveTypes();
  }, [getLeaveTypes, getLeaveByPage, getLeaves, eId]);

  return (
    <div className="user__leave__container">
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <ILAButton btnType="primary" onPress={createLeaveForm}>
          Request Leave
        </ILAButton>
      </div>

      <ListView
        dataSet={myLeaves || []}
        totalRow={data?.total || 0}
        itemCountPerPage={50}
        onNextPageClick={getLeaveByPage}
        onPrevPageClick={getLeaveByPage}
        headers={[
          {
            title: 'Leave Type',
            sortField: 'holiday_status_id',
          },
          {
            title: 'From',
            sortField: 'date_from',
          },
          {
            title: 'To',
            sortField: 'date_to',
          },
          {
            title: 'Duration',
            sortField: 'duration_display',
          },
          {
            title: 'Reason',
          },
          {
            title: 'Status',
          },
        ]}
        renderRowItem={(item: LeaveRecord) => (
          <tr key={item.id}>
            <td style={{ width: '20%' }}>{item.holiday_status_id?.[1]}</td>
            <td style={{ width: '15%' }}>
              {(item.date_from && formatDate(item.date_from, 'DD/MM/YYYY')) ||
                ''}
            </td>
            <td style={{ width: '15%' }}>
              {(item.date_to && formatDate(item.date_to, 'DD/MM/YYYY')) || ''}
            </td>
            <td style={{ width: '15%' }}>{item.duration_display}</td>
            <td style={{ width: '20%' }}>{item.name}</td>
            <td>{item.state?.[1]}</td>
          </tr>
        )}
      />
    </div>
  );
};

export default UserLeave;
