import React from 'react';
import './ila-button.scss';

export type ILAButtonProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onPress?: () => void;
  btnType?:
    | 'primary'
    | 'normal'
    | 'danger'
    | 'disable'
    | 'outline__primary'
    | 'outline__normal'
    | 'outline__danger'
    | 'outline__disable';
};

const ILAButton = ({
  className,
  children,
  onPress,
  btnType = 'normal',
  style,
}: ILAButtonProps) => {
  return (
    <button
      className={`button__container sub__tittle__small button__${btnType} ${className}`}
      onClick={onPress}
      style={style}
    >
      {children}
    </button>
  );
};

export default ILAButton;
