import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserProfile,
  UserProfileSelection,
} from '../models/profileResponse.model';
import { RootState } from '../store';

interface ProfileState {
  userProfile?: UserProfile | null;
  selection?: UserProfileSelection;
}

const initialState: ProfileState = {};

const ProfileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    updateProfile: (
      state: ProfileState,
      { payload }: PayloadAction<UserProfile>
    ) => {
      state.userProfile = payload;
    },
    setSelection: (
      state: ProfileState,
      { payload }: PayloadAction<UserProfileSelection | undefined>
    ) => {
      state.selection = payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      (action: AnyAction) => (action.type as string).endsWith('logout'),
      () => initialState
    );
  },
});

export const { updateProfile, setSelection } = ProfileSlice.actions;
export const profileSelect = (state: RootState) => state.profile;
export default ProfileSlice;
