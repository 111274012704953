import React from 'react';
import './skeleton-box.scss';

export type SkeletonBoxProps = {
  w?: string;
  h?: string;
  rounded?: string;
  className?: string;
};

const SkeletonBox = ({ w, h, rounded, className }: SkeletonBoxProps) => {
  return (
    <>
      <div
        className={`skeleton-box ${className}`}
        style={{ width: w, height: h, borderRadius: rounded }}
      ></div>
    </>
  );
};

export default SkeletonBox;
