import React, { useEffect, useState } from 'react';
import { UserProfile } from 'models/profileResponse.model';
import { Col, Image, Row } from 'react-bootstrap';
import { ChervonRight, SearchIcon, NotificationIcon } from 'assets/AssetUtil';
import '../mainLayout.scss';
import { stringToImageBase64 } from 'utils/helpers';
import SkeletonHeaderBar from 'pages/skeleton/SkeletonHeaderBar';

export type HeaderBarProps = {
  user: UserProfile | null | undefined;
  pageTitle: string;
  onOpenSetting?: () => void;
  onOpenNotification?: () => void;
  onOpenMenu?: () => void;
};

const HeaderBar: React.FC<HeaderBarProps> = ({
  user,
  onOpenSetting,
  onOpenNotification,
  onOpenMenu,
  pageTitle,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <SkeletonHeaderBar />
      ) : (
        <Row className="sticky-top bg-white align-items-center justify-content-between m-0">
          <Col className="p-0 m-0 d-flex align-items-center ila-relative">
            <button
              className="ila-btn ila-btn-hamburger"
              type="button"
              onClick={onOpenMenu}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            <h1 className="title__large mb-0 d-inline">{pageTitle}</h1>
          </Col>
          <Col className="d-flex align-items-center justify-content-end p-0 m-0 ">
            <SearchIcon role="button" className="ila-icon" />
            <NotificationIcon
              role="button"
              className="ila-icon"
              onClick={onOpenNotification}
            />
            <div
              role="button"
              className="d-flex align-items-center ila-icon icon-last-header"
              onClick={onOpenSetting}
            >
              <Image
                roundedCircle
                width={42}
                height={42}
                src={stringToImageBase64(user?.image_1920?.value)}
                className="me-2"
              />
              <span className="me-2 sub__tittle__medium">
                {user?.name?.value || ''}
              </span>
              <ChervonRight />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default HeaderBar;
