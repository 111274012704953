import { LeaveType } from 'models/leavesResponse.model';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Modal, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from 'slices/modalSlice';
import { RootState } from 'store';
import { getCurrentDate } from 'utils/helpers/DateUtils';
import ILAButton from 'components/button/CustomButton';
import { useCreatUserLeave, useOnChangeLeave } from 'api/leaveApi';
import './booking_form.scss';
import PopUpMessage from 'components/popup_message/PopUpMessage';
import { PopUpMessageType } from 'models/popUpMessage.model';
import { debounce } from 'lodash';
import { authSelect } from 'slices/authSlice';
import { HTTP_STATUS } from 'models/http-status.model';

export type BookingLeaveFormProps = {
  onRefresh: () => void;
};

export enum LeaveDayType {
  AllDay = 'All Day',
  HalfDay = 'Half Day',
}

const ErrorMessages = {
  invalidDate: 'Your To-Date is less than From-Date',
  emptyReason: 'Reason is must not empty',
};

const BookingLeaveForm: React.FC<BookingLeaveFormProps> = ({ onRefresh }) => {
  const [dataOnChange, onChange] = useOnChangeLeave();
  const dispatch = useDispatch();
  const eId = useSelector(authSelect).odooSession?.eid;
  const leaveTypes: Array<LeaveType> = useSelector(
    (state: RootState) => state.userLeave.leaveTypes
  );
  const currentDate = getCurrentDate('YYYY-MM-DD');
  const [toDate, setToDate] = useState(currentDate);
  const [fromDate, setFromDate] = useState(currentDate);

  const [totalDay, setTotalDay] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dayType, setDayType] = useState<LeaveDayType>(LeaveDayType.AllDay);
  const [response, addNewLeave] = useCreatUserLeave();
  const [reason, setReason] = useState('');

  const [leaveTypeId, setLeaveType] = useState<number | undefined>(
    leaveTypes?.[0].id
  );
  const isShowHalfDay = toDate == fromDate;

  const addingLeaveParams = useRef<object>({
    request_date_from: fromDate,
    request_date_to: toDate,
    holiday_status_id: leaveTypeId,
    request_unit_half: dayType == LeaveDayType.HalfDay,
    holiday_type: 'employee',
    employee_id: eId,
  });

  useEffect(() => {
    const dayBetween = moment(toDate, 'YYYY-MM-DD').diff(
      moment(fromDate, 'YYYY-MM-DD'),
      'days'
    );

    if (dayBetween < 0) {
      setErrorMessage(ErrorMessages.invalidDate);
      return;
    }

    errorMessage == ErrorMessages.invalidDate && setErrorMessage('');

    if (dayBetween == 0) {
      setTotalDay(dayType == LeaveDayType.HalfDay ? 0.5 : 1);
    } else {
      dayType != LeaveDayType.AllDay && setDayType(LeaveDayType.AllDay);
      setTotalDay(dayBetween + 1);
    }
  }, [toDate, fromDate, dayType, errorMessage]);

  useEffect(() => {
    const { message, code } = response;
    if (code == HTTP_STATUS.SUCCESS) {
      dispatch(
        showModal({
          type: 'modal',
          modalProps: {
            centered: true,
          },
          children: (
            <PopUpMessage
              type={PopUpMessageType.SUCCESSFUL}
              content="Creating leave is successful"
            />
          ),
        })
      );
      onRefresh();
    } else if (message && code == 50) {
      setErrorMessage(message);
    }
  }, [dispatch, onRefresh, response]);

  useEffect(() => {
    if (reason.length > 0 && errorMessage == ErrorMessages.emptyReason) {
      setErrorMessage('');
    }
  }, [errorMessage, reason]);

  useEffect(() => {
    const { code, isFetching, data = {} } = dataOnChange;
    if (
      code == HTTP_STATUS.SUCCESS &&
      Object.keys(data).length > 0 &&
      !isFetching
    ) {
      addingLeaveParams.current = {
        ...addingLeaveParams.current,
        ...data.value,
        holiday_status_id: leaveTypeId,
      };
    }
  }, [dataOnChange, leaveTypeId]);

  const submitLeave = () => {
    if (
      errorMessage == ErrorMessages.emptyReason ||
      errorMessage == ErrorMessages.invalidDate
    )
      return;
    if (reason.length == 0) {
      setErrorMessage(ErrorMessages.emptyReason);
      return;
    }
    if (errorMessage.length > 0) setErrorMessage('');

    addNewLeave({
      ...addingLeaveParams.current,
      name: reason,
    });
  };

  const onChangeToDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatedDate = moment(e.target.value).format('YYYY-MM-DD');
    callApiOnChange({ key: 'request_date_to', value: formatedDate });
    setToDate(formatedDate);
  };

  const onChangeFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatedDate = moment(e.target.value).format('YYYY-MM-DD');
    callApiOnChange({ key: 'request_date_from', value: formatedDate });
    setFromDate(formatedDate);
  };

  const onChangeLeaveType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value);
    callApiOnChange({ key: 'holiday_status_id', value: id });
    setLeaveType(id);
  };

  const callApiOnChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | number;
  }) => {
    addingLeaveParams.current = {
      ...addingLeaveParams.current,
      [key]: value,
    };
    onChange({
      field: key,
      param: addingLeaveParams.current,
    });
  };

  const debounceReason = debounce((txt: string) => setReason(txt), 350);

  const onChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debounceReason(e.target.value);
  };

  const onChangeDay = (e: React.ChangeEvent<HTMLFormElement>) => {
    setDayType(
      e.target.id == LeaveDayType.HalfDay
        ? LeaveDayType.HalfDay
        : LeaveDayType.AllDay
    );
  };

  return (
    <div className="booking__leave__container">
      <Modal.Body className="pb-0">
        <select
          className="form-select mb-3 select__leave__type "
          onChange={onChangeLeaveType}
        >
          {leaveTypes &&
            leaveTypes.map((item: LeaveType) => (
              <option key={item.id} value={item.id}>
                {item.display_name}
              </option>
            ))}
        </select>
        <textarea
          onChange={onChangeReason}
          placeholder="Input reason ..."
          className="input__reason sub__tittle__small"
        />
        <Row>
          <Col className="d-flex flex-column">
            <span className="sub__tittle__small">From</span>
            <input
              type="date"
              value={fromDate}
              onChange={onChangeFromDate}
              className="sub__tittle__small date__picker "
            />
          </Col>
          <Col className="d-flex flex-column">
            <span className="sub__tittle__small">To</span>
            <input
              type="date"
              value={toDate}
              onChange={onChangeToDate}
              className="sub__tittle__small date__picker "
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form onChange={onChangeDay}>
              <Form.Check
                type="radio"
                name="group1"
                defaultChecked
                checked={dayType == LeaveDayType.AllDay}
                label={LeaveDayType.AllDay}
                id={LeaveDayType.AllDay}
                className="sub__tittle__small"
              />
              <Form.Check
                type="radio"
                name="group1"
                checked={dayType == LeaveDayType.HalfDay}
                disabled={!isShowHalfDay}
                label={LeaveDayType.HalfDay}
                id={LeaveDayType.HalfDay}
                className="sub__tittle__small"
              />
            </Form>
          </Col>
          <Col>
            <div>
              <span className="sub__tittle__small">Total</span>
              <span className="sub__tittle__small"> {totalDay} days</span>
            </div>
          </Col>
        </Row>
        <p className="error__message sub__tittle__small">{errorMessage}</p>
      </Modal.Body>

      <Modal.Footer className="no-border">
        <ILAButton btnType="danger" onPress={() => dispatch(hideModal())}>
          Close
        </ILAButton>
        <ILAButton btnType="primary" onPress={submitLeave}>
          Submit
        </ILAButton>
      </Modal.Footer>
    </div>
  );
};

export default BookingLeaveForm;
