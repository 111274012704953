/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { ReactElement, useState } from 'react';
// import { useUpdateProfile } from 'api/userApi';
// import { HTTP_STATUS } from 'models/http-status.model';
import { Form } from 'react-bootstrap';
import './input.scss';
import {
  PopUpMessageSuccessfulIcon,
  PopUpMessageErrorIcon,
} from 'assets/AssetUtil';
import { Attribute } from 'models/attribute.model';
import { isValidDate } from 'utils/helpers/DateUtils';
import moment from 'moment';

const ErrorMessages = {
  invalid: 'Your field invalid',
  empty: 'Field  is must not empty',
};

export type ILAInputProps = {
  parameter: string;
  category: string;
  edit: boolean;
  index: number;
  valueInput: Attribute | string;
  onRefresh: (valueChange: string) => void;
};
const ILAInput: React.FC<ILAInputProps> = ({
  parameter,
  category,
  edit,
  index,
  valueInput,
}) => {
  // const [userInput, setUserInput] = useState(valueInput?.value);
  const [messageInfo, setMessageInfo] = useState('Not a valid');
  const [isShowIcon, setIsShowIcon] = useState(false);
  const [isError, setIsError] = useState('');

  // Save input change
  const handleSaveSelect = (txt: string) => {
    // Process validated and update data input
    if (txt !== String(valueInput?.selected_key)) {
      console.log('check', parameter, String(valueInput?.selected_key), txt);
      if (txt.length == 0) {
        setMessageInfo(ErrorMessages.empty);
        setIsShowIcon(true);
        setIsError('error');
        return;
      }
    }
  };

  const handleSaveInput = (txt: string) => {
    // Process validated and update data input
    if (txt !== valueInput?.value) {
      console.log('check', parameter, valueInput?.value, txt);
      if (txt.length == 0) {
        setMessageInfo(ErrorMessages.empty);
        setIsShowIcon(true);
        setIsError('error');
        return;
      }
      // updateApiProfile(userInfo?.id || 0, {
      //   [parameter]:
      //     type == 'input-select' ? userInput.toLowerCase() : userInput,
      // });
    }
  };

  const tooltip: ReactElement = (
    <div className="tooltip-container top ms-2 d-flex align-items-center justify-content-center">
      {isShowIcon && (
        <>
          {isError === 'success' ? (
            <PopUpMessageSuccessfulIcon />
          ) : (
            <PopUpMessageErrorIcon />
          )}
          <div className={`tooltip tooltip-${isError}`}>
            <p className="sub__tittle__small">{messageInfo}</p>
          </div>
        </>
      )}
    </div>
  );

  if (edit && !valueInput.readonly) {
    if (valueInput.list_value.length > 0) {
      const options: Array<ReactElement> = [];
      // Render select options by type parameter
      valueInput.list_value.forEach((item) => {
        const [key, value] = item;
        options.push(
          <option value={key} key={parameter + key}>
            {value}
          </option>
        );
      });

      return (
        <div className="d-flex align-items-center justify-content-center">
          <Form.Select
            defaultValue={valueInput?.selected_key}
            name={parameter}
            id={parameter}
            className={`
              input--${parameter} input-${isShowIcon && isError ? isError : ''}
              `}
            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleSaveSelect(e.target.value)
            }
          >
            {options}
          </Form.Select>
          {tooltip}
        </div>
      );
    } else
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Form.Control
            id={parameter}
            name={parameter}
            className={`
              input--${parameter} input-${isShowIcon && isError ? isError : ''}
              `}
            defaultValue={
              isValidDate(valueInput.value, 'DD/MM/YYYY')
                ? moment(valueInput.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : valueInput.value
            }
            type={isValidDate(valueInput.value, 'DD/MM/YYYY') ? 'date' : 'text'}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSaveInput(e.target.value)
            }
          />
          {tooltip}
        </div>
      );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="form-text-edit"
          dangerouslySetInnerHTML={{ __html: valueInput?.value }}
        ></div>
        {tooltip}
      </div>
    );
  }
};

export default ILAInput;
