import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { SessionDialog } from 'components/dialog/SessionExpiredDialog';
import { BaseApiResponse } from 'models/base_response/baseResponse.model';
import {
  ApiResponseV2,
  requestURLV2,
} from 'models/base_response/oddoApiResponseV2.model';
import { showModal } from 'slices/modalSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: 'include',
});

const baseQueryWithRefreshToken = async (
  args: FetchArgs | string,
  baseQueryApi: BaseQueryApi,
  extraOptions: object
) => {
  const res = await baseQuery(args, baseQueryApi, extraOptions);
  console.log('url request  ---');
  console.log(res.meta?.request.url);
  console.log(res.data);

  const { result, error } = res.data as BaseApiResponse;

  if (res.error || error) {
    //Oddo session expire
    if (error?.code == 100) {
      baseQueryApi.dispatch(showModal(SessionDialog));
    }
    return { error: error || res.error };
  }

  // response new API
  if (res.meta?.response?.url.includes(requestURLV2)) {
    return { data: result as ApiResponseV2 };
  }

  //response old api
  return { data: result };
};

const apiClient = createApi({
  reducerPath: 'ilaAPI',
  baseQuery: baseQueryWithRefreshToken,
  endpoints: () => ({}),
});

export default apiClient;
