/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGetProfile } from 'api/userApi';
import { UserProfile } from 'models/profileResponse.model';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { authSelect } from 'slices/authSlice';
import { updateProfile } from 'slices/profileSlice';
import MainLayout from './MainLayout';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [response, getProfile] = useGetProfile();
  const eid = useSelector(authSelect).odooSession?.eid;
  useEffect(() => {
    const { data, isSuccess, errorServer } = response || {};
    if (data && data.obj && isSuccess && !errorServer) {
      const userInfo = (data.obj as Array<UserProfile>)?.[0];
      dispatch(updateProfile(userInfo));
    }
  }, [dispatch, response]);

  useEffect(() => {
    eid && getProfile(eid);
  }, [eid, getProfile]);

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default React.memo(Dashboard);
