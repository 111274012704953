import React, { useState } from 'react';
import { ChervonRight } from 'assets/AssetUtil';
import { RouteInfo } from 'models/routeInfo';

import { MainMenuRoute } from 'AppRoute';
import SubMenu from './SubMenu';
import { useNavigate } from 'react-router-dom';

import './menuList.scss';
import AppSlice, { appSelect } from 'slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';

export type MenuProps = {
  isCollapseSidebar: boolean;
};

export type MenuItemProps = {
  isCollapseSidebar: boolean;
  item: RouteInfo;
  // onItemClick: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({ isCollapseSidebar, item }) => {
  const Icon = item.icon;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRoute = useSelector(appSelect)?.route;

  const [isCollapse, setIsCollapse] = useState(true);
  const showSubMenu = (route: RouteInfo) => {
    if (route.children == undefined || route?.children?.length == 0)
      return null;
    return <SubMenu routeInfo={route} />;
  };

  const handleMenuClick = () => {
    dispatch(AppSlice.actions.currentRoute(item));
    item.children?.length == 0 && navigate(item.path);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <>
      <Accordion.Item
        eventKey={item.path}
        className={`menu__item ${
          currentRoute?.path == item.path ? 'menu__item__select' : ''
        } ${isCollapseSidebar ? 'ila-collapse' : ''}`}
        onClick={handleMenuClick}
      >
        <Accordion.Header onClick={handleCollapse}>
          <div className="ila-menu-wp d-flex align-items-center">
            {Icon && typeof Icon != 'string' && <Icon className="menu-icon" />}
            <span className="body__large">{item.title}</span>
          </div>
          {item.children && item.children.length > 0 && (
            <ChervonRight className="icon-toggle" />
          )}
        </Accordion.Header>
        {item.children && item.children.length > 0 && (
          <Accordion.Body className="sub-menu-container">
            {showSubMenu(item)}
          </Accordion.Body>
        )}
      </Accordion.Item>
    </>
  );
};

const MenuList: React.FC<MenuProps> = ({ isCollapseSidebar }) => {
  const items = MainMenuRoute?.map((item: RouteInfo) => {
    if (item.path === '') return null;
    return (
      <MenuItem
        key={item.path}
        item={item}
        isCollapseSidebar={isCollapseSidebar}
      />
    );
  });

  return <>{items}</>;
};

export default MenuList;
