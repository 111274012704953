import { Container, Row, Col } from 'react-bootstrap';
import SkeletonBox from 'components/skeleton_box/SkeletonBox';
import Box from 'components/box/Box';

const SkeletonDashboard = () => {
  return (
    <>
      <Container fluid className="p-0 h-100">
        <Row>
          {Array.from({ length: 3 }).map((_, index) => (
            <Col key={String(index + '1')}>
              <Box
                backgroundColor="#FAFAFA"
                title={<SkeletonBox w="100px" h="30px" rounded="1rem" />}
                className="mb-4"
              >
                <div className="d-flex justify-content-between px-1">
                  <SkeletonBox w="80px" h="23px" rounded="1rem" />
                  <SkeletonBox w="80px" h="23px" rounded="1rem" />
                  <SkeletonBox w="80px" h="23px" rounded="1rem" />
                </div>
              </Box>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <Box
              backgroundColor="#FAFAFA"
              title={<SkeletonBox w="204px" h="25px" rounded="1rem" />}
              className="mb-4"
            >
              <Container>
                <Row className="">
                  <Col xs={2}>
                    <SkeletonBox w="100%" h="129px" rounded="1rem" />
                  </Col>
                  <Col xs={10}>
                    <SkeletonBox w="100%" h="113px" rounded="1rem" />
                  </Col>
                </Row>
              </Container>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{ backgroundColor: '#fafafa' }}
              className="py-3 px-4 d-flex flex-column justify-content-between h-100"
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <>
                  <div
                    key={String(index + '2')}
                    className="px-4 d-flex align-items-center justify-content-center"
                  >
                    <SkeletonBox
                      w="1rem"
                      h="1rem"
                      rounded="2px"
                      className="me-3"
                    />
                    <SkeletonBox w="100%" h="20px" rounded="1rem" />
                  </div>
                </>
              ))}
            </div>
          </Col>
          <Col>
            <div
              style={{ backgroundColor: '#FAFAFA', borderRadius: '1rem' }}
              className="px-3 py-4 mb-4 d-flex flex-column w-100 h-100 justify-content-between"
            >
              <SkeletonBox w="100%" h="178px" rounded="1rem" />
              <SkeletonBox w="100%" h="63px" rounded="1rem" />
            </div>
          </Col>
          <Col>
            <Box w="100%" h="100%" backgroundColor="#FAFAFA"></Box>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SkeletonDashboard;
