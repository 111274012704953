/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

import { IToast, IToastSlice } from 'models/toast.model';

const initialState: IToastSlice = {
  toasts: [],
};

const ToastSlice = createSlice({
  name: 'toast',
  initialState: initialState,
  reducers: {
    addToast: (state: IToastSlice, { payload }: PayloadAction<IToast>) => {
      state.toasts?.push(payload);
    },
    deleteToast: (
      state: IToastSlice,
      { payload }: PayloadAction<number | undefined | boolean>
    ) => {
      const deleteItem =
        state.toasts && state.toasts[payload as keyof typeof state.toasts];
      if (deleteItem) {
        // state.toasts = state.toasts.filter((toast) => toast.id !== payload);
      }
    },
    setToastOpen: (state: IToastSlice) => {
      state.open = true;
    },
    setToastClose: (state: IToastSlice) => {
      state.open = false;
    },
  },
});

export const { setToastOpen, setToastClose, addToast, deleteToast } =
  ToastSlice.actions;
export const toastSelect = (state: RootState) => state.toast;
export const toastsSelect = (state: RootState) => state.toast.toasts;
export default ToastSlice;
