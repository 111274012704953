import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteInfo } from '../../models/routeInfo';
import { matchRoutes, useLocation } from 'react-router-dom';
import './mainLayout.scss';

import Notification from '../notification/Notification';
import AppSetting from './component/AppSetting';
import HeaderBar from './component/HeaderBar';
import DrawerMenuBar from './component/DrawerBar';

import { Col, Container, Row } from 'react-bootstrap';
import { showModal } from 'slices/modalSlice';
import { AppRouteConfig } from 'AppRoute';
import { useSelector } from 'react-redux';
import { profileSelect } from 'slices/profileSlice';
import SkeletonDrawerBar from 'pages/skeleton/SkeletonDrawerBar';
import SkeletonHeaderBar from 'pages/skeleton/SkeletonHeaderBar';
import SkeletonDashboard from 'pages/skeleton/SkeletonDashboard';

interface LayoutProps {
  children?: React.ReactNode;
  title?: string;
}

const MainLayout = ({ children }: LayoutProps): JSX.Element => {
  let currentRouteInfo: RouteInfo | null = null;
  const location = useLocation();
  const dispatch = useDispatch();
  const currentRoutes = matchRoutes(AppRouteConfig, location);

  const profile = useSelector(profileSelect).userProfile;

  if (currentRoutes != null && currentRoutes.length > 0) {
    currentRouteInfo = currentRoutes[currentRoutes.length - 1]
      .route as RouteInfo;

    document.title = currentRouteInfo.title || 'Ila HR';
  }

  const showNotification = () => {
    dispatch(
      showModal({
        children: <Notification />,
        type: 'sidebar',
        closeOutSide: true,
        sideBarPosition: 'end',
      })
    );
  };

  const showAppSetting = () => {
    dispatch(
      showModal({
        children: <AppSetting />,
        type: 'sidebar',
        closeOutSide: true,
        sideBarPosition: 'end',
      })
    );
  };

  const showMenuMobile = () => {
    dispatch(
      showModal({
        children: <DrawerMenuBar />,
        type: 'sidebar',
        closeOutSide: true,
        sideBarPosition: 'start',
      })
    );
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Container fluid={true} className="m-0 p-0">
        <Row className="m-0">
          <Col
            lg={2}
            className="p-0 ila-pt-22 ila-pb-22 ila-ps-22 ila-mobile-menu d-none d-lg-block"
          >
            {isLoading ? <SkeletonDrawerBar /> : <DrawerMenuBar />}
          </Col>
          <Col className="p-0">
            {isLoading ? (
              <SkeletonHeaderBar />
            ) : (
              <HeaderBar
                pageTitle={currentRouteInfo?.title || ''}
                user={profile}
                onOpenNotification={showNotification}
                onOpenSetting={showAppSetting}
                onOpenMenu={showMenuMobile}
              />
            )}
            <Container fluid={true} className="content-container">
              {isLoading ? (
                <SkeletonDashboard />
              ) : (
                <div
                  key={`${currentRouteInfo?.path}`}
                  className="animated fadeInLeft"
                >
                  {children}
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MainLayout;
