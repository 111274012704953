import React from 'react';
import SkeletonBox from 'components/skeleton_box/SkeletonBox';
import Box from 'components/box/Box';

const SkeletonHeaderBar = () => {
  return (
    <>
      <div className="sticky-top">
        <Box
          backgroundColor="#fafafa"
          className="d-flex align-items-center justify-content-end p-1"
        >
          <SkeletonBox
            w="2.125rem"
            h="2.125rem"
            className="rounded-circle me-4"
          />
          <SkeletonBox w="10rem" h="1rem" rounded="1rem" />
        </Box>
      </div>
    </>
  );
};
export default SkeletonHeaderBar;
