import { FilterIcon } from 'assets/AssetUtil';
import ILAButton from 'components/button/CustomButton';
import { FilterItem } from 'components/filter/DropdonwFilter';
import React, { useState } from 'react';
import './filter-button.scss';

const FilterButton = ({
  onClick,
  filterParam,
  isSelected = false,
  className = '',
}: {
  isSelected?: boolean;
  filterParam: FilterItem;
  className?: string;
  onClick?: (filterParam: FilterItem) => void;
}) => {
  return (
    <ILAButton
      btnType="normal"
      key={`${filterParam.key} - ${filterParam.value}`}
      className={`btn__filter body__medium ${className} ${
        isSelected ? 'btn__filter__selected' : ''
      }`}
      onPress={() => onClick?.(filterParam)}
    >
      <FilterIcon width={18} height={18} />
      {filterParam.displayTitle}
    </ILAButton>
  );
};

const FilterGroup = ({
  items,
  onClickItem,
}: {
  items: Array<FilterItem>;
  onClickItem?: (item: FilterItem) => void;
}) => {
  const [currentSelectID, setSelectID] = useState(-1);

  return (
    <div className="d-flex">
      {items.map((item: FilterItem, index: number) => (
        <FilterButton
          key={index}
          isSelected={index == currentSelectID}
          filterParam={item}
          onClick={() => {
            if (currentSelectID != index) {
              setSelectID(index);
              onClickItem?.(item);
            } else {
              setSelectID(-1);
              onClickItem?.({ ...item, value: '' });
            }
          }}
        />
      ))}
    </div>
  );
};

const FilterGroupMemo = React.memo(FilterGroup);
export { FilterButton, FilterGroupMemo };
