import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AuthSlice from 'slices/authSlice';

const AppSetting = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(AuthSlice.actions.logout());
  };

  return (
    <div>
      <Button variant="danger" onClick={handleLogout}>
        Log out
      </Button>
    </div>
  );
};

export default AppSetting;
