import { OdooSession } from 'models/odooSession.model';
import { GoogleAuthResponse } from '../models/googleAuth.model';
import apiClient from './base/apiClient';
declare const google: {
  accounts: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oauth2: any;
  };
};

const authApi = apiClient.injectEndpoints({
  endpoints: (builder) => ({
    loginGoogle: builder.query<GoogleAuthResponse, void>({
      keepUnusedDataFor: 0,
      queryFn: async () => {
        const googleAuthData: GoogleAuthResponse = await new Promise(
          (resovle, reject) => {
            const client = google.accounts.oauth2.initTokenClient({
              client_id: process.env.REACT_APP_GOOGLE_API_KEY,
              scope: 'https://www.googleapis.com/auth/userinfo.profile',
              callback: (data: GoogleAuthResponse) => {
                console.log('google response -------');
                console.log(data);
                if (data && data.access_token) return resovle(data);
                return reject({ error: 'something error' });
              },
            });
            client.requestAccessToken();
          }
        );
        return {
          data: googleAuthData,
        };
      },
    }),
    getOdooSession: builder.query<OdooSession, GoogleAuthResponse>({
      keepUnusedDataFor: 0,
      query: (data) => ({
        url: '/auth_oauth/signin_reactjs',
        method: 'POST',
        body: {
          params: {
            access_token: data.access_token,
            state: {
              d: process.env.REACT_APP_SERVER_DATABASE,
              p: 3,
              r: window.origin,
            },
          },
        },
      }),
    }),
  }),
});

export const { useLazyGetOdooSessionQuery, useLazyLoginGoogleQuery } = authApi;

export default authApi;
