import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import apiClient from './api/base/apiClient';
import AllReducers from './slices';

import { persistReducer, persistStore } from 'redux-persist';

const rootReducer = {
  ...AllReducers,
  [apiClient.reducerPath]: apiClient.reducer,
};

export const config = {
  key: 'root',
  storage: storage,
  version: 1,
  whitelist: ['auth', 'profile'],
};

const persisted = persistReducer(config, combineReducers(rootReducer));

const store = configureStore({
  reducer: persisted,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiClient.middleware
    ),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
