import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import AppRoute from 'AppRoute';
import './App.scss';
import ToastList from 'components/toast/ToastList';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MainApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoute />
        <ToastList />
      </PersistGate>
    </Provider>
  );
};

root.render(<MainApp />);
