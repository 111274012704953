import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logout } from 'slices/authSlice';
import { ILAModalProps } from 'slices/modalSlice';

const SessionExpiredDialog = () => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex flex-column p-3">
      <span> Session expire ! Please login again</span>
      <Button
        className="mt-4 w-2"
        onClick={() => {
          dispatch(logout());
        }}
      >
        Ok
      </Button>
    </div>
  );
};

export const SessionDialog: ILAModalProps = {
  children: <SessionExpiredDialog />,
  type: 'modal',
  modalProps: { centered: true },
};

export default SessionDialog;
