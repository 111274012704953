import { ReactNode } from 'react';
import './box.scss';

export type BoxProps = {
  title?: string | JSX.Element;
  backgroundColor?: string;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  children?: ReactNode;
  className?: string;
  w?: string;
  h?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const Box = ({
  title,
  backgroundColor,
  iconLeft,
  iconRight,
  children,
  className,
  onClick,
  w,
  h,
}: BoxProps) => {
  return (
    <div
      onClick={onClick}
      className={`box-layout justify-content-start ${className}`}
      style={{
        backgroundColor: backgroundColor ?? '#e5edff',
        maxWidth: w,
        height: h,
      }}
    >
      {title && (
        <div
          className={`header ${
            children ? 'have-body' : ''
          } d-flex align-items-center justify-content-between`}
        >
          <div className="d-flex align-items-center">
            {iconLeft && <div className="box-icon me-1">{iconLeft}</div>}
            {title && <h5 className="title__medium mb-0 ml-2">{title}</h5>}
          </div>
          {iconRight && (
            <div className="box-icon d-flex align-items-center justify-content-end">
              {iconRight}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Box;
