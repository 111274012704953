import React, { useEffect, useState } from 'react';
import { logoILA } from 'assets/AssetUtil';
import './drawerbar.scss';
import MenuList from './MenuList';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from 'utils/route_name';
import Accordion from 'react-bootstrap/Accordion';
import SkeletonDrawerBar from 'pages/skeleton/SkeletonDrawerBar';

const DrawerMenuBar = () => {
  const navigate = useNavigate();

  // const [isCollapse, setCollapse] = useState(false);
  const onLogoClick = () => navigate(DASHBOARD);

  const drawer = (
    <>
      <img src={logoILA} className="logo" onClick={onLogoClick} />
      {/* <MenuIcon
        onClick={() => setCollapse(!isCollapse)}
        className="big-menu-icon"
      /> */}
      <Accordion className="menu-list-wp" defaultActiveKey="0" flush>
        <MenuList isCollapseSidebar={false} />
      </Accordion>
    </>
  );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <SkeletonDrawerBar />
      ) : (
        <div className="drawer__bar__container">{drawer}</div>
      )}
    </>
  );
};

export default DrawerMenuBar;
