export const requestURLV2 = 'process_request_new';

export interface DataResponse {
  obj?: object | Array<unknown>;
  total?: number;
}

export interface ApiResponseV2 {
  code?: number;
  message?: string;
  data?: DataResponse;
}

export interface OnChangeResponse {
  value?: object;
  domain?: string;
}
