import React, { useState, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import './home.scss';
import { stringToImageBase64 } from 'utils/helpers';

// import {
//   DragDropContext,
//   Draggable,
//   Droppable,
//   DropResult,
// } from 'react-beautiful-dnd';
import { EMPLOYEE } from 'utils/route_name';
import { useNavigate } from 'react-router-dom';
import { profileSelect } from 'slices/profileSlice';

import { Col, Container, Form, Row } from 'react-bootstrap';
import Box from 'components/box/Box';
// import { BankIcon, PayrollIcon, ShieldIcon } from 'assets/AssetUtil';
import { PayrollIcon } from 'assets/AssetUtil';
import { IUserData } from 'pages/profile/profileJson.model';
import { HomeJSON } from './homeJson';
import { UserEditProfile } from 'models/profileResponse.model';

const Home = () => {
  const userInfo = useSelector(profileSelect)?.userProfile;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/' + EMPLOYEE);
  };

  const tasks = [
    // {
    //   key: '1',
    //   className: 'col-xs-12 col-md-6 col-lg-4 mb-4',
    //   content: (
    //     <>
    //       <Box title="Project" iconLeft={<BankIcon />} className="h-100 mb-4">
    //         <Container>
    //           <Row>
    //             <Col>Content</Col>
    //             <Col>Content</Col>
    //             <Col>Content</Col>
    //           </Row>
    //         </Container>
    //       </Box>
    //     </>
    //   ),
    // },
    // {
    //   key: '2',
    //   className: 'col-xs-12 col-md-6 col-lg-4 mb-4',
    //   content: (
    //     <>
    //       <Box
    //         title="Annual Leave"
    //         backgroundColor="#FBF0E2"
    //         iconLeft={<ShieldIcon />}
    //         className="h-100 mb-4"
    //       >
    //         <Container>
    //           <Row>
    //             <Col>
    //               <div>
    //                 <p className="title__large">10</p>
    //                 <div>Content</div>
    //               </div>
    //             </Col>
    //             <Col>
    //               <div>
    //                 <p className="title__large">&nbsp;</p>
    //                 <div>Content</div>
    //               </div>
    //             </Col>
    //             <Col>
    //               <div>
    //                 <p className="title__large">&nbsp;</p>
    //                 <div>Content</div>
    //               </div>
    //             </Col>
    //           </Row>
    //         </Container>
    //       </Box>
    //     </>
    //   ),
    // },
    {
      key: '3',
      // className: 'col-xs-12 col-md-6 col-lg-4 mb-4',
      className: '',
      content: (
        <>
          {/* <Box
            title="Content"
            backgroundColor="#fef3fb"
            iconLeft={<ShieldIcon />}
            className="h-100 mb-4"
          >
            <Container>
              <Row>
                <Col>
                  <div>
                    <p className="title__large">10</p>
                    <div>Content</div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p className="title__large">&nbsp;</p>
                    <div>Content</div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p className="title__large">&nbsp;</p>
                    <div>Content</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Box> */}
        </>
      ),
    },
    // { key: '4', content: 'Fourth task' },
    // { key: '5', content: 'Fifth task' },
  ];

  const taskStatus = {
    requested: {
      name: 'Requested',
      items: tasks,
    },
    // toDo: {
    //   name: 'To do',
    //   items: toDos,
    // },
    // inProgress: {
    //   name: 'In Progress',
    //   items: [],
    // },
    // done: {
    //   name: 'Done',
    //   items: [],
    // },
  };

  // const [columns, setColumns] = useState(taskStatus);

  // UI Box --- edit data profile
  const generalLeftInfo: Array<ReactElement> = [];
  const generalRightInfo: Array<ReactElement> = [];

  // Generate Dynamic Form From JSON
  const profileData: Array<IUserData> = HomeJSON;
  profileData.forEach(({ category, position, name, key, content }, index) => {
    content = userInfo?.[key as keyof UserEditProfile]?.value ?? '';
    const tmp = (
      <>
        <Form.Group
          as={Row}
          className="ila-profile-des edit-form d-flex align-items-start justify-content-center"
          key={String(index + category + key)}
        >
          <Form.Label column sm={4} key={String(category + key + 'label')}>
            {name}
          </Form.Label>
          <Col sm={8}>
            <div
              key={String(category + key + 'text')}
              className="form-text-edit"
              dangerouslySetInnerHTML={{ __html: content as string }}
            ></div>
          </Col>
        </Form.Group>
      </>
    );
    if (position == 'left') {
      generalLeftInfo.push(tmp);
    } else generalRightInfo.push(tmp);
  });

  return (
    <>
      <Container fluid className="p-0">
        <Box
          title="General Information"
          iconLeft={<PayrollIcon />}
          className="cursor-pointer mb-4"
          onClick={handleNavigate}
        >
          <div className="row card-info-item">
            <div className="block-avatar d-flex align-items-center justify-content-center col-lg-2 col-md-12 col-xs-12">
              <img
                alt={userInfo?.name?.value}
                src={stringToImageBase64(userInfo?.image_1920?.value)}
                className="avatar rounded-circle border border-3 border-white"
              />
            </div>
            <div className="col-lg-10 col-md-12 col-xs-12">
              <Row>
                <div className="col-xs-12 col-md-6">
                  <div className="ila-form-content">{generalLeftInfo}</div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="ila-form-content">{generalRightInfo}</div>
                </div>
              </Row>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Home;
